.btn {
  font-size: 14px;
  font-weight: 500;
  svg{
    margin-right: 10px;
  }
  &.btn-md {
    min-width: 120px !important;
  }
  &.btn-lg {
    min-width: 160px !important;
  }
  &.btn-xl {
    min-width: 200px !important;
  }
  &.btn-sm{
    height: 40px !important;
    min-width: 140px !important;
  }
  &.btn-primary {
    min-width: 100px;
    height: 50px;
    border-radius: 4px;
    background-color: #61a351;
    border-color: #61a351;
    padding: 0 10px;
    color: white;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-shadow: none;
    .icon {
      margin-right: 10px;
      font-size: 24px;
    }
    &:focus,
    &:active,
    &:hover {
      background-color: #61a351 !important;
      border-color: #61a351 !important;
      box-shadow: 0 0 0.2rem rgba(#61a351, 50%);
    }
    &.disabled, &:disabled {
      background-color: #61a351;
      border-color: #61a351;
      &:hover,
      &:active,
      &:focus {
        background-color: #61a351;
        border-color: #61a351;
      }
    }
    &.small-btn {
      min-width: 150px;
    }
    &.delete-btn {
      background-color: #ffefee;
      border-color: #ff453a;
      color: #ff453a;
      &:hover,
      &:active {
        background-color: #ff453a;
        border-color: #ff453a;
        color: #fff;
        box-shadow: none;
      }
      &:focus {
        background-color: #ffefee;
        border-color: #ff453a;
        color: #ff453a;
        box-shadow: 0 0 0.2rem rgba(#ff453a, 50%);
      }
      &.disabled, &:disabled {
        border-color: #ff453a;
        color: #ff453a;
        &:hover,
        &:active,
        &:focus {
          border-color: #ff453a;
          color: #ff453a;
        }
      }
    }
  }
}
.btn-outline-primary{
  min-width: 100px;
  height: 50px;
  border-radius: 4px;
  background-color: #eefbeb;
  border-color: #61a351;
  padding: 0 10px;
  color: #61a351;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  &:hover,
  &:active {
    background-color: #61a351 !important;
    border-color: #61a351 !important;
    color: #fff !important;
    box-shadow: none;
  }
  &:focus,
  &:focus-visible{
    outline: 0;
    background-color: #eefbeb;
    border-color: #61a351;
    color: #61a351;
    box-shadow: 0 0 0.2rem rgba(#61a351, 50%);
  }
  &.disabled, &:disabled {
    border-color: #61a351;
    color: #61a351;
    &:hover,
    &:active,
    &:focus {
      border-color: #61a351;
      color: #61a351;
    }
  }
}
.btn-outline-secondary {
  min-width: 100px;
  height: 50px;
  border-radius: 4px;
  padding: 0 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  border: solid 1px #a3a2aa;
  color: #a3a2aa;
  &:focus{
    border-color: #a3a2aa;
    box-shadow: 0 0 0.2rem rgba(#a3a2aa, 50%);
  }
}
.btn-outline-danger{
  min-width: 100px;
  height: 50px;
  border-radius: 4px;
  background-color: #ffefee;
  border-color: #ff453a;
  padding: 0 10px;
  color: #ff453a;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  &:hover,
  &:active {
    background-color: #ff453a;
    border-color: #ff453a;
    color: #fff;
    box-shadow: none;
  }
  &:focus {
    background-color: #ffefee;
    border-color: #ff453a;
    color: #ff453a;
    box-shadow: 0 0 0.2rem rgba(#ff453a, 50%);
  }
  &.disabled, &:disabled {
    border-color: #ff453a;
    color: #ff453a;
    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
      border-color: #ff453a;
      color: #ff453a;
    }
  }
}
.btn-secondary {
  min-width: 100px;
  height: 50px;
  border-radius: 4px;
  padding: 0 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  background-color: #868686;
  &.dark{
    background-color: #3f3d56;
  }
  &.btn-and{
    &:disabled{
      background-color: #868686;
      opacity: 1;
    }
  }
}
.btn-light {
  min-width: 100px;
  height: 50px;
  border-radius: 4px;
  padding: 0 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  border: solid 1px #d8e2d6;
  background-color: #f4f6f4;
  color: #3f3d56;
  + .btn{
    margin-left: 25px;
  }
  &.delete-offer{
    margin-top: 24px;
    svg{
      margin-left: 30px;
    }
  }
}
// link-btn
.link-btn {
  min-width: 100px;
  height: 50px;
  border-radius: 4px;
  background-color: #61a351;
  padding: 0 10px;
  font-size: 14px;
  font-weight: 500;
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &:hover {
    color: white;
  }
  &.back-btn {
    background-color: transparent;
    color: #a3a2aa;
    border: 1px solid#a3a2aa;
    &:focus,
    &:focus-visible {
      outline: 0;
      border-color: #a3a2aa;
      box-shadow: 0 0 0.2rem rgba(#a3a2aa, 50%);
    }
  }
}
.btn-link-secondary{
  font-size: 16px;
  font-weight: 500;
  color: #78759d;
  display: flex;
  align-items: center;
  padding-left: 0;
  &:focus{
    outline: 0;
    box-shadow: none;
  }
  svg{
    margin-right: 2px;
    font-size: 18px;
  }
}

.btn-password-toggle{
  position: absolute;
  top: 29px;
  right: 10px;
  height: 50px;
  &:focus{
    box-shadow: none;
  }
}
.btn-switch{
  width: 115px;
  height: 50px;
  border-radius: 4px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  .custom-control{
    height: 100%;
    padding: 0 3.0rem 0 1.5rem;
    display: flex;
    align-items: center;
  }
  .custom-control-input {
    &:focus{
      &:not(:checked)~.custom-control-label::before{
        border-color: #ff453a;
      }
    }
    &:checked~.custom-control-label{
      &::before{
        border-color: #61a351;
        background-color: #61a351;
      }
      &::after {
        transform: translateX(.65rem);
      }
    }
  }
  .custom-control-label{
    &::before{
      left: 3.5rem;
      width: 20px;
      height: 10px;
      border-color: #ff453a;
      background-color: #ff453a;
    }
    &:after{
      background-color: #fff;
      left: calc(3.5rem + 2px);
      width: calc(10px - 4px);
      height: calc(10px - 4px);
    }
  }
  &.large{
    .custom-control-label{
      &::before{
        top: 0.5rem;
        left: 4.5rem;
      }
      &:after{
        top: calc(0.5rem + 2px);
        left: calc(4.5rem + 2px);
      }
    }
  }
  &.big-with-label{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    border: solid 1px #d8e2d6;
    background-color: #f4f6f4;
    padding: 15px;
    margin-bottom: 20px;
    .form-label{
      margin-bottom: 0;
      font-size: 14px;
    }
    .custom-control-label{
      margin-left: -2rem;
      margin-top: -1rem;
    }
  }
}
.absolute-btn {
  position: absolute;
  right: 30px;
  top: 30px;
}
.top-filter-buttons {
  position: absolute;
  right: 0;
  top: -71px;
}