.modal {
  &.choose-offer-modal {
    .modal-header {
      display: flex;
      align-items: center;
      margin: 0 30px;
      padding: 30px 0 10px 0 !important;
      border-bottom: 1px solid #d8e2d6;
      .back-btn {
        margin-right: 20px;
      }
      .close {
        display: none;
      }
    }
    .modal-dialog {
      max-width: 1005px;
      width: 100%;
    }
    .modal-body {
      padding: 30px 30px 5px 30px;
    }
    .row {
      margin: 0 -12px;
      .col-sm-6 {
        padding: 0 12px;
      }
    }
  }
  &.game-list-modal{
    .modal-dialog{
      max-width: 300px;
    }
    .modal-content{
      padding-left: 20px;
      padding-right: 20px;
    }
    .close {
      display: none;
    }
    .modal-header,
    .modal-body{
      padding: 20px 0 10px;
    }
    .col-sm-12 {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }
    .gamer-game-list{
      .game-icon{
        width: 40px;
        height: 40px;
        border-radius: 4px;
        object-fit: cover;
        margin-right: 10px;
      }
      .game-name{
        font-size: 14px;
        font-weight: 500;
        color: #3f3d56;
        line-height: 1.3;
        width: calc(100% - 50px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  &.delete-modal{
    .modal-dialog{
      max-width: 430px;
    }
    .modal-new-title{
      font-size: 20px;
      font-weight: 600;
      color: #3f3d56;
      margin-bottom: 10px;
    }
    .modal-body{
      padding: 30px 0 20px;
      p{
        font-size: 14px;
        font-weight: 500;
        color: #3f3d56;
        margin-bottom: 0;
        line-height: 1.3;
      }
    }
    .modal-content{
      padding-left: 20px;
      padding-right: 20px;
    }
    .modal-footer{
      border: 0;
    }
    .heading-with-button{
      margin-bottom: 10px;
      h4{
        font-weight: 600;
        color: #3f3d56;
        line-height: 1.3;
      }
      .link-text{
        font-size: 11px;
        line-height: 1.3;
        text-decoration: underline;
      }
    }
    .game-list{
      margin-bottom: 10px;
      height: 0;
      overflow: hidden;
      transition: height 0.25s linear .5s;
      &.show-all{
        height: 115px;
        overflow: auto;
      }
      li{
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        .game-name{
          font-size: 14px;
          font-weight: 500;
          line-height: 1.3;
          color: #ff453a;
        }
      }
    }
  }
  &.signout-modal{
    .modal-dialog{
      max-width: 460px;
    }
    .modal-content{
      padding-left: 20px;
      padding-right: 20px;
    }
    .modal-header{
      padding-bottom: 10px;
    }
    .modal-heading{
      line-height: 1.3;
    }
    .modal-body{
      padding: 30px 0 20px;
      p{
        font-size: 14px;
        font-weight: 500;
        color: #3f3d56;
        margin-bottom: 0;
        line-height: 1.3;
      }
    }
    .modal-footer{
      border: 0;
      .btn{
        margin: 0 20px 0 0;
        &:last-child{
          margin-right: 0;
        }
      }
    }
  }
  &.game-detail-modal{
    .modal-dialog{
      max-width: 460px;
    }
    .offer-detail-box{
      padding: 0 20px;
      border: 0;
      border-radius: 0;
      margin-bottom: 0;
      .offer-detail-box-top{
        margin-bottom: 20px;
      }
      .games-desc-text{
        p{
          font-size: 13px;
          font-weight: normal;
          line-height: 1.85;
          color: #67666c;
        }
        strong{
          font-size: 12px;
          font-weight: 500;
          line-height: normal;
          color: #3f3d56;
        }
      }
    }
    .reward-text{
      font-size: 12px;
      color: #3f3d56;
      margin-bottom: 5px;
      text-align: right;
      &.bold{
        font-weight: bold;
      }
    }
    .modal-footer{
      margin-left: 20px;
      margin-right: 20px;
      .btn.btn-primary{
        padding: 0 40px;
      }
    }
  }
  &.modification-modal{
    .modal-dialog{
      max-width: 700px;
    }
    .modal-content{
      padding-left: 20px;
      padding-right: 20px;
    }
    .modal-footer{
      border: 0;
      .btn{
        margin: 0;
        margin-left: 20px;
      }
    }
  }
  &.view-event-modal{
    .modal-dialog{
      max-width: 700px;
    }
    .modal-content{
      padding-left: 20px;
      padding-right: 20px;
    }
    .event-desc-text{
      color: #3f3d56;
      p{
        font-size: 12px;
        margin-bottom: 5px;
      }
      strong{
        font-size: 16px;
        line-height: 1.4;
        font-weight: bold;
        margin-bottom: 20px;
        display: block;
      }
    }
    .modal-footer{
      border: 0;
      .btn{
        margin: 0;
        margin-left: 20px;
      }
    }
  }
  &.event-disable-modal{
    .modal-dialog{
      max-width: 490px;
    }
    .modal-content{
      padding-left: 20px;
      padding-right: 20px;
    }
    .modal-footer{
      border: 0;
      .btn{
        margin: 0;
        margin-left: 20px;
      }
    }
  }
  &.gamer-cashback-modal{
    .modal-dialog{
      max-width: 390px;
    }
    .modal-content{
      padding-left: 20px;
      padding-right: 20px;
    }
    .close {
      display: none;
    }
    .modal-header{
      padding: 20px 0 10px;
    }
    .modal-body{
      padding: 0 0 20px;
    }
    .modal-footer{
      border-top: 0;
      padding-top: 0;
    }
    .list-items {
      display: flex;
      align-items: center;
      padding: 20px 0;
      border-bottom: 1px solid #d8e2d6;
      &.rewards-list-items{
        justify-content: space-between;
        padding: 20px 0 10px;
        span{
          width: auto;
          font-size: 14px;
        }
      }
    }
    .gamer-game-list{
      max-height: calc(100vh - 300px);
      overflow: auto;
      .game-icon{
        width: 24px;
        height: 24px;
        object-fit: cover;
        margin-right: 6px;
        border-radius: 0.9px;
        border: solid 0.5px #d8e2d6;
      }
      .game-name{
        font-size: 16px;
        font-weight: 700;
        color: #3f3d56;
        line-height: normal;
        width: calc(100% - 30px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .button-container{
      text-align: right;
      padding-top: 20px;
    }
  }
  .modal-heading{
    font-size: 20px;
    color: #3f3d56;
    font-weight: 600;
  }
  p{
    font-size: 14px;
    font-weight: 500;
    color: #3f3d56;
  }
}

.modal-header,
.modal-body,
.modal-footer{
  padding: 20px 0;
  border-radius: 0;
}

// offer-detail-box
.offer-detail-box {
  border: solid 1px #c9c8cf;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 25px;
  display: block;
  &.less-bottom-margin{
    margin-bottom: 16px;
  }
  .offer-detail-box-top {
    display: flex;
    .image-box {
      min-width: 80px;
      width: 80px;
      height: 80px;
      border-radius: 4px;
      margin-right: 15px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 4px;
      }
    }
    .offer-detail-box-top-body {
      display: flex;
      width: 100%;
      justify-content: space-between;
      position:relative;
      .left-side {
        position: relative;
        strong {
          display: block;
          font-weight: bold;
          color: #3f3d56;
          margin-bottom: 15px;
          word-break: break-word;
        }
        p {
          font-size: 12px;
          font-weight: 500;
          color: #a3a2aa;
          margin-bottom: 15px;
        }
        span {
          background-color: #daead6;
          width: 80px;
          height: 20px;
          border-radius: 2px;
          font-size: 11px;
          color: #61a351;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .partner-image{
        position: absolute;
        right: 0;
        bottom: 20px;
        max-height: 16px;
        &.bottom-medium{
          bottom: 40px;
        }
      }
    }
  }
}

// games-tags-list-wrapper
.games-tags-list-wrapper {
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 20px;
  strong {
    font-size: 12px;
    color: #3f3d56;
    margin-right: 5px;
  }
  .game-name {
    font-size: 12px;
    font-weight: 500;
    color: #3f3d56;
    margin-right: 10px;
  }
  .games-tags-list {
    display: flex;
    .games-tags {
      padding: 3px 8px;
      border-radius: 4px;
      border: solid 1px #d8e2d6;
      background-color: #f4f6f4;
      display: flex;
      align-items: center;
      margin-right: 5px;
      .image-box {
        width: 30px;
        height: 30px;
        border-radius: 4px;
        margin-left: -5px;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          border-radius: 4px;
        }
      }
      span {
        color: #a3a2aa;
        font-size: 11px;
        margin-left: 3px;
      }
      &.view-all-btn {
        margin-left: 0;
        cursor: pointer;
      }
    }
  }
}

// games-desc-text
.games-desc-text {
  strong {
    color: #3f3d56;
    font-size: 12px;
    display: block;
    margin-bottom: 5px;
    font-weight: 500;
  }
  p {
    color: #67666c;
    line-height: 24px;
    font-size: 13px;
  }
}

// disable event modal

.event-modal{
  padding-top: 30px;
  padding-bottom: 0;
  p{
    font-size: 14px;
    font-weight: 500;
    color: #3f3d56;
    margin-bottom: 20px;
    line-height: 1.3;
  }
  .heading-with-button{
    margin-bottom: 10px;
    h4{
      font-size: 13px;
      font-weight: 600;
      color: #3f3d56;
      line-height: 1.3;
    }
    .link-text{
      font-size: 11px;
      line-height: 1.3;
      text-decoration: underline;
    }
  }
  .game-list{
    margin-bottom: 10px;
    height: 100px;
    overflow: hidden;
    transition: height 0.25s linear .5s;
    &.show-all{
      height: 200px;
      overflow: auto;
    }
    &.small{
      height: 56px;
      overflow: hidden;
      &.show-all{
        height: 112px;
        overflow: auto;
      }
    }
    li{
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      .game-icon{
        width: 40px;
        height: 40px;
        border-radius: 4px;
        object-fit: cover;
        margin-right: 10px;
      }
      .game-name{
        font-size: 14px;
        font-weight: 500;
        color: #3f3d56;
        line-height: 1.3;
      }
    }
  }
}

.errors-modal {
  max-height: 250px;
  overflow: auto;
  p {
    margin-bottom: 10px !important;
    color: #ff453a !important;
  }
}
