.App {
    display: flex;
    flex-direction: row;
  }

  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }

  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }

  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .App-link {
    color: #61dafb;
  }

  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  amplify-auth-container,
  [data-amplify-authenticator] {
    width: calc(100% - 45.313%);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .banner-customizable {
    background-color: #363636;
    justify-content: center;
    width: 45.313%;
    height: 100vh;
    align-items: center;
    display: flex;
  }

  .amplify-button[data-variation='primary'] {
    background: #61a351 !important;
  }

  .amplify-button[data-variation='primary']:hover {
    opacity: 0.8;
  }

  .amplify-button[data-variation='link'] {
    color: #61a351 !important;
  }

  .amplify-button[data-variation='link']:hover {
    background-color: transparent !important;
    text-decoration: underline;
  }

  .amplify-tabs-item {
    color: #152939 !important;
  }

  .amplify-tabs-item:active {
    color: #61a351 !important;
  }
