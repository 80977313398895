// left-nav-wrapper
.left-nav-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: #37652c;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  overflow: auto;
  z-index: 1;
  &:hover{
    .slider-toggle {
      display: block;
      img {
        box-shadow: 0 2px 15px #00000026;
      }
    }
  }
  .logo-container{
    margin-bottom: 20px;
  }
  .logo-box {
    margin-top: 30px;
    text-align: center;
    img{
      max-height: 40px;
    }
  }
  .small-logo{
    display: none;
    max-height: 50px !important;
    margin: 0 auto;
  }
  .user-logo-box {
    img{
      max-height: 40px;
      max-width: 200px;
    }
  }
  &.left-nav-inner-wrapper {
    .left-nav {
      margin: auto 20px;
    }
    li {
      a {
        margin: 0;
        border-radius: 4px;
        padding: 18px 20px;
        display: flex;
        align-items: center;
        margin-bottom: 1px;
        .nav-text {
          margin-left: 15px;
        }
        .icon {
          font-size: 24px;
        }
        svg{
          font-size: 24px;
        }
        &.active,
        &:hover {
          background-color: #61a351;
          color: #fff;
          .icon,
          .nav-text,
          svg ,
          svg g g{
            color: white;
            fill: #fff;
          }
        }
      }
    }
  }
}

// left-nav
.left-nav {
  margin-left: 20px;
  li {
    &:last-child {
      a {
        .nav-count {
          &:after {
            border: none;
          }
        }
      }
    }
  }
  &.no-cursor{
    a{
      cursor: default;
    }
  }
  a {
    color: #61a351;
    margin: 20px 0;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    &:focus-visible,
    &:focus{
      outline: 0;
      .nav-count {
        color: white;
        border-color: white;
        &:after {
          border-color: white;
        }
      }
      .nav-text {
        color: white;
      }
    }
    &.active {
      .nav-count {
        color: white;
        border-color: white;
        &:after {
          border-color: white;
        }
      }
      .nav-text {
        color: white;
      }
    }
  }
  .nav-count {
    border: 2px solid #61a351;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    font-weight: 500;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      left: -38px;
      top: 13px;
      border-top: 2px dotted #61a351;
      height: 60px;
      transform: rotate(90deg);
      width: 40px;
      cursor: default;
    }
  }
  .nav-text {
    font-size: 15px;
    font-weight: 600;
    color: #61a351;
    margin-left: 6px;
  }
}

// signout-btn
.signout-btn-wrapper {
  padding-top: 20px;
  .signout-btn {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    margin: 0 0 28px 42px;
    font-size: 16px;
    font-weight: 600;
    color: #61a351;
    .icon-signout {
      margin-right: 10px;
      font-size: 24px;
    }
  }
  .version{
    font-size: 12px;
    font-weight: 600;
    color: #61a351;
    padding-left: 42px;
    margin-bottom: 20px;
  }
}
