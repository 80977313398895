
.form-submit{
  appearance: none;
  -webkit-appearance: none;
  cursor: default !important;
}
// form-group
.form-group {
  margin-bottom: 40px;
  &.last-form-group {
    margin-bottom: 0;
  }
  label {
    color: #3f3d56;
    font-size: 13px;
    margin-bottom: 11px;
    font-weight: 400;
    line-height: 1;
    &.label-with-icon {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 0;
      margin-bottom: 10px;
      .icon-circle-help {
        color: #c9c8cf;
        font-size: 20px;
        cursor: pointer;
      }
      svg{
        font-size: 20px;
        color: #c9c8cf;
      }
      &.small-label{
        max-width: calc(100% - 80px);
      }
    }
    &.only-label{
      color: #3f3d56;
      font-size: 13px;
      margin-bottom: 11px;
      font-weight: 500;
    }
    &.label-md{
      font-size: 14px;
      font-weight: 500;
    }
  }
}

// form-control
.form-control {
  height: 50px;
  border: solid 1px #d8e2d6;
  font-size: 14px;
  color: #3f3d56;
  font-weight: 400;
  padding: 0 15px;
  &:focus {
    box-shadow: 0 0 0.2rem rgba(#61a351, 50%);
    border: solid 1px #61a351;
  }
  &:disabled,
  &[readonly] {
    border-color:#d8e2d6;
    background-color: #f4f6f4;
    color: #3f3d56;
  }
  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #c9c8cf;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    color: #c9c8cf;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    color: #c9c8cf;
  }
  &:-moz-placeholder { /* Firefox 18- */
    color: #c9c8cf;
  }
}

// textarea
textarea {
  resize: none;
  &.form-control {
    height: 150px;
    padding: 15px;
  }
}

// select
select {
  -moz-appearance: none;
  -webkit-appearance: none;
  &.form-control {
    padding-right: 50px;
    background-image: url('../../images/down-arrow.png');
    background-repeat: no-repeat;
    background-position: 98% 50%;
  }
}

// custom-radio
.custom-radio {
  margin-bottom: 0;
  input {
    display: none;
    &:checked {
      & + span {
        &:before {
          background-color: #fff;
          border-color: #61a351;
        }
        &:after {
          display: block;
        }
      }
    }
    &:disabled {
      & + span {
        &:before {
          background-color: #fff;
          border-color: #999;
          opacity: 0.5;
        }
        &:after {
          display: block;
          content: '';
          opacity: 0.5;
          background-color: #fff;
        }
        cursor: default;
      }
      &:checked {
        & + span {
          &:before {
            opacity: 0.5;
            background-color: #fff;
            border-color: #999;
          }
          &:after {
            opacity: 0.5;
            display: block;
            content: '';
            background-color: #999;
          }
        }
      }
    }
  }
  span {
    position: relative;
    padding-left: 28px;
    color: #3f3d56;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    margin-bottom: 0;
    line-height: 1.4;
    display: inline-block;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      background-color: white;
      width: 18px;
      height: 18px;
      border: 1px solid #999;
      border-radius: 50%;
    }
    &:after {
      content: '';
      position: absolute;
      top: 5px;
      left: 5px;
      width: 8px;
      height: 8px;
      display: none;
      background-color: #61a351;
      border-radius: 100%;
    }
  }
}

// custom-checkbox
.custom-checkbox {
  position: relative;
  input {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    height: 20px;
    width: 20px;
    cursor: pointer;
    &:checked {
      & + span {
        &:before {
          background-color: #61a351;
          border-color: #61a351;
        }
        &:after {
          display: block;
        }
      }
    }
    &:disabled {
      & + span {
        &:before {
          background-color: #999;
          border-color: #999;
          opacity: 0.5;
        }
        cursor: default !important;
      }
    }
  }
  span {
    position: relative;
    font-size: 14px;
    font-weight: 500;
    color: #3f3d56;
    line-height: 1.4;
    padding-left: 30px;
    cursor: pointer;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      background-color: white;
      width: 18px;
      height: 18px;
      border: 1px solid #c9c8cf;
      border-radius: 2px;
      cursor: pointer;
    }
    &:after {
      content: '';
      position: absolute;
      top: 3px;
      left: 6px;
      width: 6px;
      height: 10px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      display: none;
      cursor: pointer;
    }
  }
}

// custom-search
.custom-search {
  position: relative;
  margin-bottom: 0;
  width: 250px;
  border-radius: 4px;
  &.w-300{
    width: 300px;
  }
  &.w-200{
    width: 230px;
  }
  &.border-1{
    border: solid 1px #d8e2d6;
  }
  .form-control {
    border: none;
    font-weight: 400;
    padding-left: 45px;
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      font-size: 13px;
      font-weight: normal;
      color: #a5a5a5;
    }
    &::-moz-placeholder { /* Firefox 19+ */
      font-size: 13px;
      font-weight: normal;
      color: #a5a5a5;
    }
    &:-ms-input-placeholder { /* IE 10+ */
      font-size: 13px;
      font-weight: normal;
      color: #a5a5a5;
    }
    &:-moz-placeholder { /* Firefox 18- */
      font-size: 13px;
      font-weight: normal;
      color: #a5a5a5;
    }
  }
  .icon-search {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 24px;
    height: 50px;
    width: 45px;
    background-color: white;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.placeholder-ellipsis {
    .form-control:placeholder-shown {
      text-overflow: ellipsis;
    }
  }
}

// input-with-icon
.input-with-icon {
  display: flex;
  align-items: center;
  .icon {
    color: #61a351;
    font-size: 24px;
    cursor: pointer;
    margin-left: 15px;
    &.icon-delete {
      color: red;
    }
  }
  input{
    max-width: calc(100% - 80px);
  }
}

// input-add-btn
.input-add-btn {
  margin-bottom: 20px;
}

// input-group-with-icons
.input-group-with-icons {
  display: flex;
  margin: 0 -12px;
  .group-col {
    width: 45%;
    padding: 0 12px;
  }
  .icon-col {
    padding: 0 18px 0 12px;
    width: 10%;
    margin: 0 auto;
    text-align: center;
  }
  .icon-wrapper {
    text-align: left;
    .icon {
      cursor: pointer;
      font-size: 24px;
      margin-top: 38px;
      display: inline-block;
      & + .icon{
        margin-left: 10px;
      }
    }
    .icon-circle-plus-green {
      color: #61a351;
    }
    .icon-delete {
      color: red;
    }
  }
}

// for-small-input
.for-small-input {
  padding-right: 92px !important;
}

//custom-dropdown
.custom-dropdown {
  &.sort-dropdown {
    .dropdown-toggle {
      min-width: 50px;
      padding: 0;
    }
    .icon-sort-asc{
      transform: rotate(180deg);
      display: inline-block;
    }
  }
  &.more-dropdown{
    .dropdown-toggle {
      min-width: 50px;
      margin-top: -20px;
      margin-right: -16px;
      padding: 0;
      color: #c9c8cf !important;
      svg{
        margin: 0;
      }
    }
    .dropdown-menu {
      padding: 8px 20px;
      min-height: 100px;
      .dropdown-item {
        padding: 8px 0;
        display: flex;
        align-items: center;
      }
      &.three-dropdown-item{
        min-height: 140px;
      }
      &.four-dropdown-item{
        min-height: 165px !important;
      }
      &.five-dropdown-item{
        min-height: 205px !important;
      }
    }
  }
  &.notification-dropdown{
    .dropdown-toggle.btn-success {
      min-width: 50px;
      padding: 0;
      background-color: transparent !important;
      border-color: transparent !important;
      span{
        font-size: 20px;
        color: #3f3d56;
        &.notification-count{
          font-size: 10px;
          color: white;
          background-color: #ff453a;
          border-radius: 6px;
          right: 10px;
          top: 16px;
        }
      }
    }
    .dropdown-menu {
      padding: 20px 20px 5px;
      max-width: 410px;
      width: 410px;
      .dropdown-heading {
        font-size: 20px;
        font-weight: 600;
        color: #3f3d56;
      }
      .dropdown-item {
        padding: 15px 0;
        white-space: initial;
        border-bottom: 1px solid #d8e2d6;
        &:last-child {
          border-bottom: 0;
        }
        &.active {
          background: #fff;
          .heading-text {
            font-weight: 600;
          }
          &:hover{
            background: #fff;
          }
        }
        .heading-text{
          font-size: 14px;
          color: #3f3d56;
          line-height: 1.4;
          margin-bottom: 5px;
          font-weight: normal;
        }
        .time{
          font-size: 11px;
          font-weight: normal;
          color: #78759d;
          margin-bottom: 0;
        }
      }
    }
  }
  &.filter-dropdown {
    .dropdown-toggle {
      display: flex;
      align-items: center;
      .heading-text {
        font-size: 14px;
        padding: 0 10px;
      }
      .count {
        height: 20px;
        width: 20px;
        background-color: #61a351;
        border-radius: 50%;
        color: white;
        font-size: 11px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .dropdown-menu {
      padding: 10px 20px;
      &:after {
        right: 15%;
      }
      .dropdown-item {
        padding: 15px 0;
      }
    }
  }
  .dropdown-toggle {
    border-radius: 4px;
    background-color: #ffffff !important;
    border-color:#ffffff !important;
    height: 50px;
    color: #3f3d56 !important;
    font-size: 24px;
    padding: 0 20px;
    span {
      font-size: 24px;
    }
    &.btn-primary{
      font-size: 14px !important;
      font-weight: 500;
      min-width: 100px;
      height: 50px;
      border-radius: 4px;
      background-color: #61a351 !important;
      border-color: #61a351 !important;
      color: #fff !important;
    }
    &:after {
      display: none;
    }
    &:focus-visible,
    &:focus {
      outline: 0;
      box-shadow: 0 0 0.2rem rgba(#61a351, 50%) !important;
    }
    &:active {
      background-color: #ffffff;
      border-color:#ffffff;
      color: #3f3d56;
    }
  }
  .dropdown-menu {
    position: relative;
    border-radius: 10px;
    box-shadow: 0 0 20px 0 rgba(34, 78, 23, 0.2);
    background-color: #ffffff;
    border: none;
    padding: 20px;
    &[x-placement^=bottom]{
      &:after {
        content: "";
        bottom: 100%;
        right: 5%;
        border: solid transparent;
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(136, 183, 213, 0);
        border-bottom-color: white;
        border-width: 10px;
        margin-left: -10px;
      }
    }
    &[x-placement^=top]{
      &:after {
        content: "";
        bottom: -19px;
        right: 8%;
        border: solid transparent;
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(136, 183, 213, 0);
        border-top-color: white;
        border-width: 10px;
        margin-left: -10px;
      }
    }
    .dropdown-heading {
      display: block;
      font-size: 20px;
      font-weight: 600;
      color: #3f3d56;
      border-bottom: 1px solid #d8e2d6;
      padding-bottom: 10px;
      margin-bottom: 20px;
    }
    .dropdown-item {
      font-size: 14px;
      font-weight: 500;
      color: #3f3d56;
      padding: 15px 20px;
      .rounded{
        min-width: 20px;
        width: 20px;
        height: 20px;
        background-color: #3f3d56;
        color: #fff;
        border-radius: 50% !important;
        margin-right: 14px;
        margin-top: 4px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        svg{
          width: 16px;
          margin-right: 0;
          height: 16px;
        }
      }
      svg{
        margin-right: 10px;
      }
      &.active {
        background-color: #61a351;
        color: white;
        border-radius: 4px;
        span {
          display: block;
        }
        &:hover {
          background-color: #61a351;
        }
      }
      &:hover {
        background-color: white;
      }
    }
  }
  &.tm-dropdown-menu{
    .dropdown-menu{
      .dropdown-item{
        white-space: normal;
        min-width: 130px;
      }
    }
  }
}
.border-container{
  padding: 20px 8px 0;
  border-radius: 4px;
  border: solid 1px #d8e2d6;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.input-group-text{
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  min-width: 50px;
  justify-content: center;
  background-color: #f4f6f4;
}

.nav-tabs {
  border-bottom-color: #d8e2d6;
  margin-bottom: 30px;
  &.extra-top-margin {
    margin-top: 25px;
  }
  .nav-link {
    margin-bottom: -1px;
    border: 1px solid transparent;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #a3a2aa;
    padding: 10px 0;
    margin-right: 30px;
    &:hover,
    &.active {
      color: #61a351;
      border-color: transparent;
      border-bottom-color: #61a351;
      &:focus{
        border-bottom-color: darken(#61a351, 10%);
        box-shadow: none;
        color: darken(#61a351, 10%);
      }
    }
    &:focus{
      box-shadow: none;
      outline: none;
      border-color: transparent;
    }
  }
}
.custom-multiselect{
  position: relative;
  button ~ div,
  .css-48ayfv ~ div{
    width: 100%;
  }
  button,
  .css-48ayfv{
    height: 50px;
    width: 100%;
    border: solid 1px #d8e2d6;
    font-size: 14px;
    color: #3f3d56;
    font-weight: 400;
    box-shadow: none;
    &:focus-visible,
    &:focus {
      outline: 0;
      box-shadow: 0 0 0.2rem rgba(#61a351, 50%);
    }
  }
  .css-1pcexqc-container{
    margin-top: -9px;
    padding: 8px 0;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border: solid 1px #d8e2d6;
    background-color: #fff;
  }
  .css-1jllj6i-control,
  .css-6y1x9t-control{
    margin: 0px 15px 8px;
    min-width: 100px;
  }
  .css-1vr111p-option,
  .css-1qprcsu-option{
    min-width: 100px;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.3;
    color: #3f3d56;
    padding: 8px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
  }
  .css-1qprcsu-option{
    font-weight: bold;
    background: #eefbeb;
  }
}
.css-1w67djx-control,
.css-ylj6fz-control{
  min-width: calc(100% - 16px) !important;
}
.clear-icon{
  position: absolute;
  top: 44px;
  right: 30px;
  cursor: pointer;
  svg{
    height: 20px;
    width: 20px;
  }
}
.form-text{
  display: inline-block;
  font-size: 11px;
  line-height: 1.5;
}
.form-label {
  font-size: 13px;
  font-weight: 500;
  color: #3f3d56;
  margin-top: 5px;
  margin-bottom: 11px;
  line-height: 1;
  &.label-md{
    font-size: 14px;
    font-weight: 500;
  }
}

// Custom selectbox

.css-yk16xz-control{
  height: 50px;
  border-radius: 4px !important;
  border: solid 1px #d8e2d6 !important;
}
.css-1pahdxg-control{
  height: 50px;
  border: solid 1px #d8e2d6 !important;
  box-shadow: none !important;
  &:hover{
    border-color: #d8e2d6 !important;
  }
}
.css-1wa3eu0-placeholder{
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: normal;
  color: #c9c8cf !important;
}
.css-1hb7zxy-IndicatorsContainer{
  .css-1okebmr-indicatorSeparator{
    display: none;
  }
}
.css-26l3qy-menu{
  border-radius: 10px !important;
  border: solid 1px #d8e2d6 !important;
  box-shadow: none !important;
  font-size: 13px;
  font-weight: 500;
  color: #3f3d56;
  margin-top: 0 !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-top: 0 !important;
  .css-yt9ioa-option{
    padding: 12px 20px;
    &:active{
      background-color: #eefbeb;
    }
  }
  .css-1n7v3ny-option{
    padding: 12px 20px;
    background-color: #eefbeb;
    &:active{
      color: #fff;
      background-color: #61a351;
    }
  }
  .css-9gakcf-option{
    padding: 12px 20px;
    background-color: #61a351;
    &:active{
      background-color: #37652c;
    }
  }

}
.css-1uccc91-singleValue{
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3;
}
.css-1v99tuv{
  padding-right: 17px;
}
.offer-filter{
  .custom-multiselect{
    .clear-icon{
      top: 45px;
      svg{
        height: 20px;
        width: 20px;
      }
    }
    .css-1jllj6i-control,
    .css-6y1x9t-control{
      margin: 0px 10px 8px;
      min-width: auto;
    }
    .css-1vr111p-option,
    .css-1qprcsu-option{
      min-width: auto;
      padding: 8px 10px;
      word-break: break-word;
      input[type=checkbox]{
        margin-right: 0 !important;
        margin-left: 5px;
      }
    }
    .css-151xaom-placeholder{
      font-size: 13px;
    }
  }
}

.custom-datepicker {
  position: relative;
  input[type="text"]{
    height: 50px;
    width: 100%;
    border: solid 1px #d8e2d6;
    font-size: 14px;
    color: #3f3d56;
    font-weight: 400;
    box-shadow: none;
    padding: 8px 8px 5px 8px;
    border-radius: 0.25rem;
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: #c9c8cf;
    }
    &::-moz-placeholder { /* Firefox 19+ */
      color: #c9c8cf;
    }
    &:-ms-input-placeholder { /* IE 10+ */
      color: #c9c8cf;
    }
    &:-moz-placeholder { /* Firefox 18- */
      color: #c9c8cf;
    }
    &:focus-visible,
    &:focus {
      outline: 0;
      box-shadow: 0 0 0.2rem rgba(#61a351, 50%);
    }
  }
  .calendar-icon{
    position: absolute;
    top: 34px;
    right: 15px;
    &.more-top{
      top: 42px;
    }
  }
  &.range-picker{
    input[type="text"]{
      font-size: 13px;
      &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        font-size: 12px;
      }
      &::-moz-placeholder { /* Firefox 19+ */
        font-size: 12px;
      }
      &:-ms-input-placeholder { /* IE 10+ */
        font-size: 12px;
      }
      &:-moz-placeholder { /* Firefox 18- */
        font-size: 12px;
      }
    }
  }
  .react-datepicker__close-icon{
    right: 10px;
    top: 1px;
    &::after{
      color: #3f3d56;
      background: transparent;
      font-size: 1.5rem;
    }
  }
  &.with-icon{
    .react-datepicker__close-icon{
      right: 40px;
    }
  }
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range{
  background-color: #61a351 !important;
}
.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected{
  background-color: #61a351 !important;
}
.form-with-button{
  position: relative;
  .btn-primary{
    top: 0;
    right: 0;
    min-width: 120px;
  }
  .form-control {
    &:disabled,
    &[readonly] {
      border-color:#d8e2d6;
      background-color: #f4f6f4;
      color: #3f3d56;
      font-weight: 500;
    }
  }
}
.slider-container{
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  .MuiSlider-root{
    color: #61a351;
    width: 85%;
  }
  .MuiSlider-thumb{
    width: 12px;
    height: 12px;
    margin-top: -5px;
  }
  .MuiSlider-thumb.Mui-focusVisible, .MuiSlider-thumb:hover {
    box-shadow: 0px 0px 0px 8px rgba(#61a351, 0.16);
  }
  .MuiSlider-thumb.MuiSlider-active {
    box-shadow: 0px 0px 0px 14px rgba(#61a351, 0.16);
  }
  .MuiSlider-thumb > span{
    top: -40px;
    font-size: 11px;
  }
  .MuiSlider-thumb > span > span{
    width: 40px;
    height: 40px;
  }
  &.slider-with-input{
    justify-content: space-between;
    .MuiSlider-root{
      width: calc(100% - 140px);
      margin: 0 10px;
    }
    .form-control{
      max-width: 60px;
      padding: 0 7px;
      text-align: center;
    }
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}
.inline-form {
  display: flex;
  align-items: center;
  .form-label{
    margin: 0 11px 0 0;
    font-size: 14px;
    font-weight: 500;
    color: #3f3d56;
  }
}
.custom-timezone {
  [class$="-control"] {
    height: 50px;
    border-radius: 4px;
    border: solid 1px #d8e2d6;
  }
  [class$="-placeholder"] {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: normal;
    color: #c9c8cf !important;
  }
  [class$="-indicatorSeparator"] {
    display: none;
  }
  [class$="-indicatorContainer"] {
    margin-right: 7px;
    svg{
      display: none;
    }
    &::after{
      content: '';
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #cccccc;
    }
  }
  .css-1gtu0rj-indicatorContainer{
    margin-right: 7px;
    &::after{
      content: '';
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #666;
    }
  }
}
.tour-delete-icon{
  min-height: 50px;
  display: inline-flex;
  align-items: center;
  width: 100%;
}
