// common css
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font-family: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
html{
	min-height: 100%;
}
body {
  font-family: 'Poppins', sans-serif;
	font-size: 16px;
	line-height: 1;
	box-sizing: border-box;
	background: #f6f9f6 url(../../images/qx-watermark.svg) no-repeat bottom right;
	height: 100%;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
a {
	text-decoration: none;
	&:hover {
		text-decoration: none;
	}
	&:focus-visible,
	&:focus {
		outline: 0;
		box-shadow: 0 0 0.2rem rgba(#61a351, 50%);
	}
}
.pos-relative{
	position: relative !important;
}
.cursor-default{
	cursor: initial !important;
}
.m-h50{
	min-height:45px !important;
}
.white-icon{
	svg{
	  color: #fff;
	}
}
.pink-border{
	border: solid 1px #ff3f8e;
}
.text-danger{
	color: #ff453a !important;
}
.text-green{
	color: #61a351 !important;
}
.opt-text{
	color: #61a351 !important;
	font-weight: 500;
	font-size: 14px;
}
.link-text{
	color: #61a351 !important;
	cursor: pointer;
	&:hover{
		text-decoration: underline;
	}
	&.underline{
		text-decoration: underline;
	}
}
.show-link{
	color: #61a351 !important;
	cursor: pointer;
	text-decoration: underline;
	font-size: 13px;
	display: inline-flex;
    align-items: center;
    padding-bottom: 30px;
}
.inline-block{
	display: inline-block !important;
}
.placeholder-text{
	color: #c9c8cf;
}
.mb-8px{
	margin-bottom: 8px !important;
}
.mb-30{
	margin-bottom: 30px !important;
}
.mb-20{
	margin-bottom: 20px !important;
}
.mr-70{
	margin-right: 70px !important;
}

.pb-10{
	padding-bottom: 10px !important;
}
.text-gamer-bold {
	font-size: 16px;
	font-weight: bold;
	color: #3f3d56;
}
.font-13 {
	font-size: 13px !important;
}
.font-14 {
	font-size: 14px !important;
}
.font-18 {
	font-size: 18px !important;
}
.font-medium {
	font-weight: 500 !important;
}
.font-600 {
	font-weight: 600 !important;
}
.bold-small-font {
	font-weight: 600 !important;
	font-size: 16px !important;
	color: #a3a2aa !important;
}
.line-ellipses {
	display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.middle-filter-btn{
	margin-right: 20px;
	.btn-filter {
		margin-left: 0;
	}
}
.help-icon{
	font-size: 20px !important;
	color: #c9c8cf;
	margin-left: 5px;
	&.text-orange {
		color: #ff6344;
	}
}
.disabled {
	.dropdown-item{
		pointer-events: none;
		opacity: 0.5;
	}
}

.tooltip-position {
	position:absolute;
	right: 14%;
	top: 12px;
}
// custom-image-upload
.custom-image-upload {
	input {
		display: none;
	}
	label {
		cursor: pointer;
		width: 102px;
		height: 40px;
		border-radius: 4px;
		border: solid 1px #a3a2aa;
		background-color: #f6f9f6;
		font-size: 13px;
		font-weight: 500;
		color: #a3a2aa;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		img{
			margin-right: 10px;
		}
	}
}

// custom-image-upload-wrapper
.custom-image-upload-wrapper {
	margin-bottom: 20px;
	position: relative;
	strong {
		display: block;
		font-size: 14px;
		color: #3f3d56;
		font-weight: 500;
		margin-bottom: 10px;
	}
	.custom-image-upload{
		position: absolute;
    bottom: 0;
    left: 220px;
	}
}

// custom-image-upload-box
.custom-image-upload-box {
	input {
		display: none;
	}
	label {
		width: 200px;
		height: 200px;
		border: dashed 1px #d8e2d6;
  	background-color: #f4f6f4;
		border-radius: 35px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		text-align: center;
		flex-direction: column;
		img{
			width: 52px;
  			height: 52px;
			min-height: 52px;
			margin-bottom: 4px;
		}
		p {
			color: #3f3d56;
			font-size: 14px;
			line-height: 1.4;
			span {
				color: #61a351;
				text-decoration: underline;
			}
		}
	}
}

// uploaded-image-wrapper
.uploaded-image-wrapper {
	display: flex;
	align-items: flex-end;
	max-width: 200px;
}

// uploaded-image
.uploaded-image {
	width: 200px;
	height: 200px;
	border-radius: 20px;
	border: 1px solid #d8e2d6;
	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
		border-radius: 20px;
		max-height: 100%;
		max-width: 100%;
	}
	&.small{
		width: 120px;
		height: 120px;
	}
}
.scrollbar {
	&::-webkit-scrollbar {
		width: 6px;
		margin-right: 10px;
		right: 50px;
		border-radius: 3px;
	}
	&::-webkit-scrollbar-track {
		background: #d8e2d6;
		border-radius: 3px;
	}
	&::-webkit-scrollbar-thumb {
		background: #8ab67f;
		border-radius: 3px;
	}
	&::-webkit-scrollbar-thumb:hover {
		background: #8ab67f;
		border-radius: 3px;
	}
}
.badge{
	padding: 3px 9px;
  border-radius: 2px;
	font-size: 11px;
	line-height: 14px;
	font-weight: normal;
	&.badge-primary{
		color: #61a351 !important;
		background-color: #daead6 !important;
	}
	&.badge-secondary{
		color: #8b8b8b !important;
		background-color: #e5e5ea !important;
	}
	&.badge-warning{
		color: #ff453a !important;
		background-color: rgba(#ff453a, 0.2) !important;
	}
	&.badge-pending{
		color: #9e872b !important;
		background-color: #eae6d6 !important;
	}
	&.badge-approved{
		color: #ebf8ff !important;
		background-color: #5fa6c9 !important;
	}
	&.badge-rejected{
		color: #ff3f8e !important;
		background-color: #ffddeb !important;
	}
	&.badge-template{
		color: #fff !important;
		background-color: #a5a5a5 !important;
	}
	&.draft{
		color: #9e872b !important;
		background-color: #eae6d6 !important;
	}
}
.ribbon-container{
	height: 70px;
	width: 70px;
	position: absolute;
	top: -20px;
  left: -20px;
	z-index: 1;
	overflow: hidden;
	& ~ strong{
		padding-left: 42px;
		padding-right: 10px;
	}
}
.ribbon{
	position: absolute;
	top: 18px;
  left: -23px;
	min-width: 100px;
	transform: rotate(-45deg);
	z-index: 2;
}
.avatar-list{
	display: flex;
  align-items: center;
	margin-bottom: 10px;
	&:last-child{
		margin-bottom: 0;
	}
	.avatar-image{
		width: 40px;
		height: 40px;
		margin: 0 10px 0 0;
		border-radius: 4px;
		img{
			height: 100%;
			width: 100%;
			border-radius: 4px;
		}
	}
	.avatar-title{
		font-size: 14px;
		font-weight: 500;
		color: #3f3d56;
	}
}

.delete-form-button{
	margin-top: 43px;
	display: inline-block;
	svg{
		font-size: 24px;
	}
}
.divider{
	width: 100%;
	height: 1px;
  margin: 50px 0 20px;
  background-color: #d8e2d6;
}

.url-link {
	cursor: pointer;
	display: inline-block;
	&:hover {
		text-decoration: underline;
		color: #61a351;
	}
}


// accordion start here
.accordion{
	.card{
		border-radius: 4px;
		border-color: #d8e2d6;
		margin-bottom: 30px;
		&:not(:last-of-type){
			border-bottom: solid 1px #d8e2d6;
			border-radius: 4px;
		}
		&:not(:first-of-type){
			border-radius: 4px;
		}
		.card-header{
			background-color: #fff;
			padding: 0;
			.btn-link{
				font-size: 14px;
				font-weight: 600;
				line-height: 20px;
				color: #3f3d56;
				text-decoration: none;
				width: 100%;
				padding: 11px 15px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				&:focus{
					box-shadow: none;
				}
			}
		}
		.collapse.show ~ .card-header{
			background: red;
		}
		.card-body{
			padding: 15px;
			font-size: 14px;
			line-height: 1.71;
			color: #3f3d56;
		}
	}
}
._loading_overlay_wrapper {
	._loading_overlay_overlay {
		position: fixed;
		height: 100%;
		width: 100%;
	}
}
.bold-heading {
	font-size: medium !important;
  font-weight: bold !important;
}

.redux-toastr{
	.top-left,
	.top-right,
	.top-center,
	.bottom-left,
	.bottom-right,
	.bottom-center{
		width: 470px;
	}

	.toastr{
		&.rrt-success{
			background-color: #DEF2D6;
			color: #61a351 !important;
			.toastr-icon {
				fill: #61a351 !important;
			}
			.close-toastr {
				color: #61a351 !important;
			}
		}
		&.rrt-error {
			background-color: #EBC8C2;
			color: #ff453a;
			.toastr-icon {
				fill: #ff453a !important;
			}
			.close-toastr {
				color: #ff453a !important;
			}
		}
		.rrt-left-container{
			width: 60px;
			.rrt-holder{
				width: 60px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
		.rrt-middle-container{
			width: calc(90% - 60px);
			margin-left: 60px;
			height: 70px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-family: 'Poppins', sans-serif;
			.rrt-title{
				font-size: 15px;
    		font-weight: 600;
				margin: 0;
    		line-height: 1.3;
			}
		}
		.close-toastr{
			font-size: 20px;
			color: #fff;
			opacity: 0.8;
		}
	}
}
.no-record{
	font-size: 26px;
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 200px;
	margin: 0 auto;
	&.coming-soon{
		font-weight: 500;
		min-height: 400px;
		flex-direction: column;
		img{
			margin-bottom: 20px;
		}
	}
}


// Editor
.rdw-editor-wrapper{
	border-radius: 4px;
  border: solid 1px #d8e2d6;
	min-height: 180px;

	.rdw-editor-toolbar{
		border: 0;
		border-bottom: 1px solid #F1F1F1;
	}
	.rdw-editor-main{
		padding: 0 12px;
		font-size: 14px;
		font-weight: 500;
		color: #3f3d56;
		line-height: 1.3;
	}
}
.content-with-btn{
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.comment-container{
	padding-bottom: 95px;
	position: relative;
	height: calc(100vh - 217px);
	max-height: calc(100vh - 217px);
	&.admin-comment-container{
		height: calc(100vh - 325px);
		max-height: calc(100vh - 325px);
	}
	.comment-content{
		overflow: auto;
		height: 100%;
		max-height: 100%;
	}
	.comment-content-box{
		display: flex;
		flex-direction: column;
		margin-bottom: 20px;
		margin-top: 10px;
		max-width: 60%;
		&.right-box{
			margin-left: auto;
			.comment-head{
				text-align: right;
				padding-right: 20px;
			}
			.comment-inner{
				margin-right: 20px;
			}
		}
		.comment-head{
			font-size: 14px;
			color: #3f3d56;
			margin-bottom: 10px;
			line-height: 1.3;
			span{
				&.green-color{
					font-weight: 600;
					color: #61a351;
				}
			}
		}
		.comment-inner{
			padding: 15px;
			border-radius: 4px;
			border: solid 1px #d8e2d6;
			background-color: #f4f6f4;
			font-size: 13px;
			font-weight: 500;
			color: #3f3d56;
			line-height: 1.4;
			p{
				margin-bottom: 15px;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
	.comment-box{
		position: absolute;
		bottom: 30px;
		left: 0;
		width: 100%;
		.form-control{
			height: 60px;
			padding-right: 130px;
		}
		.btn{
			position: absolute;
			top: 5px;
			right: 6px;
		}
	}
}

.tooltip {
	.tooltip-inner{
		width: 100%;
		max-width: 275px;
		padding: 20px;
		border-radius: 10px;
		box-shadow: 0 0 20px 0 rgba(34, 78, 23, 0.2);
		background: #fff;
		font-family: 'Poppins', sans-serif;
		font-size: 13px;
		font-weight: 500;
		line-height: 1.3;
		color: #3f3d56;
		text-align: left;
	}
	&.event-tooltip{
		.tooltip-inner{
			max-width: 500px;
		}
	}
}
.bs-tooltip-auto[x-placement^=right] .arrow::before,
.bs-tooltip-right .arrow::before {
	border-right-color: #fff;
}

.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.bs-tooltip-bottom .arrow::before {
	border-bottom-color: #fff;
}

.bs-tooltip-auto[x-placement^=left] .arrow::before,
.bs-tooltip-left .arrow::before {
	border-left-color: #fff;
}
.bs-tooltip-auto[x-placement^=top] .arrow::before,
.bs-tooltip-top .arrow::before {
	border-top-color: #fff;
}

.filter-boxes{
	width: 100%;
	margin-right: 20px;
	&.bottom-margin{
		margin-bottom: 30px;
	}
	.row{
		margin-left: -8px;
		margin-right: -8px;
		.col, .col-md-6, .col-md-4, .col-md-3, .col-sm-2, .col-sm-4 {
			padding-left: 8px;
			padding-right: 8px;
		}
	}
	&.offer-filter{
		.col{
			flex: 0 0 20%;
			max-width: 20%;
		}
		.col-md-3{
			padding-right: 8px;
			padding-left: 8px;
		}
	}
	.form-group {
		margin-bottom: 0;
		&.mb-20{
			margin-bottom: 20px;
		}
	}
	&.trans-exchange-filter{
		margin-right: 0;
		margin-bottom: 20px;
		.col,
		.col-md-3{
			flex: 0 0 25%;
			max-width: 25%;
			padding-left: 8px;
			padding-right: 8px;
		}
		.form-group{
			margin-bottom: 20px;
		}
	}
	&.trans-redeem-filter{
		margin-right: 0;
		margin-bottom: 20px;
		.row{
			.col {
				flex: 0 0 14.285%;
				max-width: 14.285%;
			}
			.col-md-3{
				padding-left: 8px;
				padding-right: 8px;
			}
		}
		.form-group{
			margin-bottom: 20px;
			label{
				font-size: 12px;
			}
		}
	}
}
.public-DraftEditorPlaceholder-root{
	color: #c9c8cf !important;
	font-weight: 400;
}
.image-tag {
	display: flex;
	align-items: center;
	margin-right: 5px;
	.image-box {
		width: 50px;
		height: 50px;
		border-radius: 4px;
		border: solid 1px #d8e2d6;
		margin-right: 10px;
		img {
			height: 100%;
			width: 100%;
			object-fit: cover;
			border-radius: 4px;
		}
	}
	span {
		font-size: 14px;
  	font-weight: 500;
  	color: #3f3d56;
	}
}
.white-container{
	background-color: white;
	border-radius: 10px;
	padding: 30px 30px 20px;
	margin-bottom: 30px;
	.page-inner-heading{
		margin-bottom: 20px;
		display: flex;
		justify-content: space-between;
	}
	.content-des{
		font-size: 14px;
		font-weight: 500;
		color: #3f3d56;
		margin-bottom: 0;
		line-height: 1.3;
	}
}

.disable-btn-overlay{
	position: absolute;
    right: 0px;
    top: -25px;
    color: #c9c8cf;
    font-size: 20px;
    cursor: pointer;
}

.clone-draft-error {
	margin-left: 65px;
}


// toggle full page

.slider-toggle {
	position: fixed;
	top: 100px;
	left: 235px;
	cursor: pointer;
	z-index: 2;
	.ring{
		img {
			border-radius: 50%;
		}
	}
}
.navbar-slider{
	.left-nav-wrapper{
		width: 100px;
		&.left-nav-inner-wrapper li a{
			justify-content: center;
			padding: 18px 14px;
			.nav-text{
				display: none;
			}
		}
		.left-nav{
			margin-left: 35px;
			.nav-text{
				display: none;
			}
		}
		.signout-text,
		.main-logo {
			display: none;
		}
		.small-logo{
			display: block;
		}
	}
	.signout-btn-wrapper{
		.signout-btn{
			margin-left: 35px;
		}
		.version{
			padding-left: 5px;
		}
	}
	.slider-toggle {
		left: 85px;
	}
	.main-right-wrapper {
    padding: 0 30px;
    width: calc(100% - 100px);
    margin-left: 100px;
	}
}
.absolute-search {
    position: absolute !important;
    top: -80px;
    right: 140px;
	&.absolute-top{
		top: 199px;
		right: 175px;
	}
}
.btn-filter {
	height: 50px;
	width: 50px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	margin-left: 20px;
	background: #61a351;
	border: 1px solid #61a351;
	border-radius: 4px;
	cursor: pointer;
	position: relative;
	&.active{
		background: #fff;
		svg{
			g{
				path:last-child{
					fill: #61a351;
				}
			}
		}
	}
}
.badge-filter{
	display: inline-block;
  position: absolute;
  top:-3px;
  right: -3px;
  width: 18px;
  height: 18px;
  background-color: #deb332;
  border: 3px solid #fff;
  border-radius: 50%;
}
.remove-filter{
	display: none !important;
}
.custom-scroll-height{
	height: calc(100vh - 365px) !important;
	&.full-height{
		height: calc(100vh - 260px) !important;
	}
	&.max{
		height: calc(100vh - 460px) !important;
		&.full-height{
			height: calc(100vh - 360px) !important;
		}
		&.full-page-height{
			height: calc(100vh - 260px) !important;
		}
	}
	&.medium{
		height: calc(100vh - 400px) !important;
		&.full-height{
			height: calc(100vh - 310px) !important;
		}
	}
	&.small{
		height: calc(100vh - 350px) !important;
		&.full-height{
			height: calc(100vh - 350px) !important;
		}
	}
}
.filter-icon-position{
	position: absolute;
	right: 0;
	top: -80px;
}
.bold-tabs{
	&.nav-tabs{
		.nav-link{
			font-size: 20px;
			font-weight: 700;
			letter-spacing: 0.28px;
			border-bottom: 0;
			margin-bottom: 0;
			padding-bottom: 14px;
			position: relative;
			&::after{
				content: '';
				position: absolute;
				width: 45px;
				height: 6px;
				left: 50%;
				bottom: 0;
				transform: translate(-50%, 0);
			}
			&.active,
			&:hover{
				color: #3f3d56;
				&::after{
					content: '';
					background: #61a351;
				}
				&:focus{
					box-shadow: none;
					color: darken(#3f3d56, 10%);
				  }
			}
		}
	}
}
.cursor-pointer {
	cursor: pointer;
}
.divider-space{
	display: flex;
	align-items: center;
	padding-top: 45px;
	.or-divider{
		margin: 0 50px 0 10px;
	}
}
.hide-tooltip {
	display: none;
}
.opt-in-popover{
	font-family: 'Poppins', sans-serif;
	padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px 0 rgba(34, 78, 23, 0.1);
	width: 100%;
	max-width: 382px;
	border: 0;
	.arrow{
		display: none;
	}
	.popover-body{
		padding: 0;
		.pop-title{
			font-size: 14px;
			font-weight: 500;
			color: #3f3d56;
			margin-bottom: 12px;
		}
		.fee-text{
			font-size: 14px;
			color: #3f3d56;
			margin-bottom: 24px;
			display: inline-block;
		}
		.badge{
			min-width: 50px;
			margin-bottom: 24px;
		}
		.row{
			margin-left: -10px;
			margin-right: -10px;
			.col-sm-6{
				padding-left: 10px;
				padding-right: 10px;
			}
		}
	}
}
.subscription-detail {
	margin-top: 6px;
	padding-left: 28px;
	font-size: 11px;
}
