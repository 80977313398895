@font-face {
  font-family: 'icomoon';
  src:  url('../../fonts/icomoon.eot?xx9ov4');
  src:  url('../../fonts/icomoon.eot?xx9ov4#iefix') format('embedded-opentype'),
    url('../../fonts/icomoon.ttf?xx9ov4') format('truetype'),
    url('../../fonts/icomoon.woff?xx9ov4') format('woff'),
    url('../../fonts/icomoon.svg?xx9ov4#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon', sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 16px;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-up-arrow:before {
  content: "\e913";
}
.icon-down-arrow:before {
  content: "\e914";
}
.icon-back-arrow:before {
  content: "\e901";
}
.icon-delete:before {
  content: "\e902";
}
.icon-circle-plus-green:before {
  content: "\e903";
}
.icon-circle-help:before {
  content: "\e904";
}
.icon-circle-plus:before {
  content: "\e905";
}
.icon-search:before {
  content: "\e906";
}
.icon-circle-pencil:before {
  content: "\e907";
}
.icon-sort:before {
  content: "\e908";
}
.icon-sort-asc:before {
  content: "\e908";
}
.icon-filter:before {
  content: "\e909";
}
.icon-list-view:before {
  content: "\e90a";
}
.icon-grid-view:before {
  content: "\e90b";
}
.icon-faqs:before {
  content: "\e90c";
}
.icon-settings:before {
  content: "\e90d";
}
.icon-events:before {
  content: "\e90e";
}
.icon-games:before {
  content: "\e90f";
}
.icon-offer:before {
  content: "\e910";
}
.icon-analytics:before {
  content: "\e911";
}
.icon-dashboard:before {
  content: "\e912";
}
.icon-signout:before {
  content: "\e900";
}
.icon-bell:before {
  content: "\e951";
}
.icon-tournament:before {
  content: "\e915";
}
.icon-gamers:before {
  content: "\e916";
}
