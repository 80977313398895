.table-container {
  width: 100%;
  position: relative;
  .game-image {
    width: 40px;
    height: 40px;
    margin: 0 10px 0 0;
    object-fit: contain;
    border-radius: 6px;
  }
  .strong{
    font-weight: 600;
  }
  .icon{
    color: #3f3d56;
    svg{
      font-size: 24px;
    }
  }
  .link{
    color: #61a351;
    text-decoration: underline;
    word-break: break-word;
    &:focus{
      box-shadow: none;
      color: darken(#61a351, 10%);
    }
  }
  .big-text-common{
    font-size: 16px;
    font-weight: 600;
  }
  .icon-image{
    max-width: 70px;
    max-height: 40px;
  }
}
.table-head {
  height: 50px;
  border-radius: 4px;
  box-shadow: 0 0 20px 0 rgba(220, 229, 217, 0.5);
  background-color: #ffffff;
  display: flex;
  margin-bottom: 20px;
}
.table-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 100%;
  max-width: 100%;
}
.th {
  display: flex;
  align-items: center;
  padding: 0 10px;
  font-size: 13px;
  line-height: 1.3;
  font-weight: 600;
  color: #3f3d56;
  .sort-btn-box {
    display: inline-block;
    margin-left: 5px;
  }
}

.table-body {
  display: flex;
  flex-direction: column;
  // flex-wrap: wrap;
  margin: 0;
  overflow: auto;
  max-height: calc(100vh - 385px);
  min-height: calc(100vh - 385px);
  &.scrollbar{
    width: calc(100% + 15px);
    padding-right: 10px;
  }
  &.no-scrollbar{
    overflow: visible;
    max-height: auto;
    min-height: auto;
  }
  .table-row {
    height: 60px;
    min-height: 60px;
    border-radius: 4px;
    box-shadow: 0 0 20px 0 rgba(220, 229, 217, 0.5);
    margin-bottom: 20px;
    background-color: #ffffff;
    &.inactive{
      background-color: #f8f8f8;
    }
  }
  .medium{
    font-weight: 500;
  }
}
.td {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  font-size: 13px;
  line-height: 1.3;
  color: #3f3d56;
  word-break: break-word;
}

.six-column{
  .table-row {
    .th,
    .td {
      &:nth-child(1) {
        flex: 0 0 25%;
        max-width: 25%;
      }
      &:nth-child(2) {
        flex: 0 0 22%;
        max-width: 22%;
      }
      &:nth-child(3) {
        flex: 0 0 18%;
        max-width: 18%;
      }
      &:nth-child(4) {
        flex: 0 0 17%;
        max-width: 17%;
      }
      &:nth-child(5) {
        flex: 0 0 10%;
        max-width: 10%;
      }
      &:nth-child(6) {
        flex: 0 0 8%;
        max-width: 8%;
        justify-content: center;
      }
    }
  }
}
.five-column{
  .table-row {
    .th,
    .td {
      &:nth-child(1) {
        flex: 0 0 30%;
        max-width: 30%;
      }
      &:nth-child(2) {
        flex: 0 0 23%;
        max-width: 23%;
        justify-content: center;
      }
      &:nth-child(3) {
        flex: 0 0 22%;
        max-width: 22%;
        justify-content: center;
      }
      &:nth-child(4) {
        flex: 0 0 17%;
        max-width: 17%;
      }
      &:nth-child(5) {
        flex: 0 0 8%;
        max-width: 8%;
        justify-content: center;
      }
    }
  }
}
.admin-offer-table{
  .table-row {
    .th,
    .td {
      &:nth-child(1) {
        flex: 0 0 20%;
        max-width: 20%;
      }
      &:nth-child(2) {
        flex: 0 0 42%;
        max-width: 42%;
      }
      &:nth-child(3) {
        flex: 0 0 15%;
        max-width: 15%;
      }
      &:nth-child(4) {
        flex: 0 0 15%;
        max-width: 15%;
      }
      &:nth-child(5) {
        flex: 0 0 8%;
        max-width: 8%;
        justify-content: center;
      }
    }
  }
}
.rejected-table{
  .table-row {
    .th,
    .td {
      &:nth-child(1) {
        flex: 0 0 25%;
        max-width: 25%;
      }
      &:nth-child(2) {
        flex: 0 0 25%;
        max-width: 25%;
      }
      &:nth-child(3) {
        flex: 0 0 22%;
        max-width: 22%;
      }
      &:nth-child(4) {
        flex: 0 0 20%;
        max-width: 20%;
      }
      &:nth-child(5) {
        flex: 0 0 8%;
        max-width: 8%;
        justify-content: center;
      }
    }
  }
}
.game-seven-column{
  .table-body .table-row{
    height: 80px;
    min-height: 80px;
  }
  .strong{
    font-size: 16px;
  }
  .game-image{
    height: 60px;
    width: 60px;
    margin-right: 0;
  }
  .table-row {
    .th,
    .td {
      &:nth-child(1) {
        flex: 0 0 9.5%;
        max-width: 9.5%;
      }
      &:nth-child(2) {
        flex: 0 0 20.5%;
        max-width: 20.5%;
      }
      &:nth-child(3) {
        flex: 0 0 15%;
        max-width: 15%;
      }
      &:nth-child(4) {
        flex: 0 0 15%;
        max-width: 15%;
      }
      &:nth-child(5) {
        flex: 0 0 15%;
        max-width: 15%;
      }
      &:nth-child(6) {
        flex: 0 0 15%;
        max-width: 15%;
      }
      &:nth-child(7) {
        flex: 0 0 10%;
        max-width: 10%;
        justify-content: center;
      }
    }
  }
}
.four-column{
  .table-body .table-row{
    height: 70px;
    min-height: 70px;
  }
  .strong{
    font-size: 16px;
    font-weight: 600;
  }
  .link{
    display: inline-block;
    margin-left: 5px;
  }
  .table-row {
    padding: 0 10px;
    .th,
    .td {
      &:nth-child(1) {
        flex: 0 0 28%;
        max-width: 28%;
      }
      &:nth-child(2) {
        flex: 0 0 28%;
        max-width: 28%;
      }
      &:nth-child(3) {
        flex: 0 0 32%;
        max-width: 32%;
      }
      &:nth-child(4) {
        flex: 0 0 12%;
        max-width: 12%;
        justify-content: flex-end;
      }
    }
  }
}
.offer-table {
  .strong{
    font-size: 16px;
    font-weight: 600;
  }
  .table-body .table-row{
    height: 70px;
    min-height: 70px;
  }
  .dropdown-toggle{
    &.btn{
      svg{
        margin: 0;
      }
    }
  }
  .table-row {
    .td{
      color: #67666c;
    }
    .th,
    .td {
      &:nth-child(1) {
        flex: 0 0 28%;
        max-width: 28%;
      }
      &:nth-child(2) {
        flex: 0 0 60%;
        max-width: 60%;
      }
      &:nth-child(3) {
        flex: 0 0 12%;
        max-width: 12%;
        justify-content: center;
      }
    }
  }
}

.offer-partner-table{
  .table-body .table-row{
    height: 70px;
    min-height: 70px;
  }
  .strong{
    font-size: 16px;
    font-weight: 600;
    color: #3f3d56;
  }
  .link{
    display: inline-block;
  }
  .table-row {
    .td {
      color: #67666c;
    }
    .th,
    .td {
      &:nth-child(1) {
        flex: 0 0 15%;
        max-width: 15%;
      }
      &:nth-child(2) {
        flex: 0 0 20%;
        max-width: 20%;
      }
      &:nth-child(3) {
        flex: 0 0 15%;
        max-width: 15%;
      }
      &:nth-child(4) {
        flex: 0 0 15%;
        max-width: 15%;
      }
      &:nth-child(5) {
        flex: 0 0 15%;
        max-width: 15%;
      }
      &:nth-child(6) {
        flex: 0 0 10%;
        max-width: 10%;
      }
      &:nth-child(7) {
        flex: 0 0 10%;
        max-width: 10%;
        justify-content: center;
      }
    }
  }
}

.event-table {
  .strong{
    font-size: 16px;
    font-weight: 600;
    color: #3f3d56;
  }
  .table-body .table-row{
    height: 70px;
    min-height: 70px;
  }
  .dropdown-toggle{
    &.btn{
      svg{
        margin: 0;
      }
    }
  }
  .table-row {
    padding: 0 10px;
    .td{
      color: #67666c;
    }
    .th,
    .td {
      &:nth-child(1) {
        flex: 0 0 20%;
        max-width: 20%;
      }
      &:nth-child(2) {
        flex: 0 0 15%;
        max-width: 15%;
      }
      &:nth-child(3) {
        flex: 0 0 55%;
        max-width: 55%;
      }
      &:nth-child(4) {
        flex: 0 0 10%;
        max-width: 10%;
        justify-content: center;
      }
    }
  }
}

.event-partner-table {
  .strong{
    font-size: 16px;
    font-weight: 600;
    color: #3f3d56;
  }
  .table-body .table-row{
    height: 70px;
    min-height: 70px;
  }
  .dropdown-toggle{
    &.btn{
      svg{
        margin: 0;
      }
    }
  }
  .table-row {
    padding: 0 10px;
    .td{
      color: #67666c;
    }
    .th,
    .td {
      &:nth-child(1) {
        flex: 0 0 17%;
        max-width: 17%;
      }
      &:nth-child(2) {
        flex: 0 0 13%;
        max-width: 13%;
      }
      &:nth-child(3) {
        flex: 0 0 15%;
        max-width: 15%;
      }
      &:nth-child(4) {
        flex: 0 0 15%;
        max-width: 15%;
      }
      &:nth-child(5) {
        flex: 0 0 19%;
        max-width: 19%;
      }
      &:nth-child(6) {
        flex: 0 0 10%;
        max-width: 10%;
      }
      &:nth-child(7) {
        flex: 0 0 10%;
        max-width: 10%;
        justify-content: center;
      }
    }
  }
}
.admin-user-table {
  .dropdown-toggle{
    &.btn{
      svg{
        margin: 0;
      }
    }
  }
  .table-row {
    padding: 0 10px;
    .td{
      font-size: 14px;
    }
    .th,
    .td {
      &:nth-child(1) {
        flex: 0 0 20%;
        max-width: 20%;
        font-weight: 500;
      }
      &:nth-child(2) {
        flex: 0 0 20%;
        max-width: 20%;
        font-weight: 500;
      }
      &:nth-child(3) {
        flex: 0 0 30%;
        max-width: 30%;
      }
      &:nth-child(4) {
        flex: 0 0 20%;
        max-width: 20%;
      }
      &:nth-child(5) {
        flex: 0 0 10%;
        max-width: 10%;
        justify-content: center;
      }
    }
  }
}

.trans-exchange-table{
  .table-body .table-row{
    height: 70px;
    min-height: 70px;
  }
  .big-text{
    font-size: 16px;
    font-weight: 600;
    display: inline-flex;
    align-items: center;
  }
  .multiline-text{
    flex-wrap: wrap;
    span{
      width: 100%;
      margin-bottom: 3px;
    }
  }
  .link{
    display: inline-block;
    cursor: pointer;
    width: auto;
    margin-bottom: 0;
  }
  .logo{
    width: 60px;
    height: 60px;
    margin: 10px 20px 10px 0;
    border-radius: 6px;
    object-fit: cover;
  }
  .table-row {
    .th,
    .td {
      &:nth-child(1) {
        flex: 0 0 20%;
        max-width: 20%;
      }
      &:nth-child(2) {
        flex: 0 0 10%;
        max-width: 10%;
      }
      &:nth-child(3) {
        flex: 0 0 20%;
        max-width: 20%;
      }
      &:nth-child(4) {
        flex: 0 0 20%;
        max-width: 20%;
      }
      &:nth-child(5) {
        flex: 0 0 15%;
        max-width: 15%;
      }
       &:nth-child(6) {
        flex: 0 0 15%;
        max-width: 15%;
      }

    }
  }
}

.trans-redeem-table{
  .table-body .table-row{
    height: 60px;
    min-height: 60px;
  }
  .big-text{
    font-size: 16px;
    font-weight: 600;
  }
  .link{
    display: inline-block;
  }
  .logo{
    width: 20px;
    height: 20px;
    margin-right:5px;
    border-radius: 1px;
    border: solid 0.5px #d8e2d6;
    object-fit: cover;
  }
  .table-row {
    .th,
    .td {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(7) {
        flex: 0 0 14.285%;
        max-width: 14.285%;
      }
    }
  }
}
.gamer-offer-table{
  .table-body .table-row{
    height: 60px;
    min-height: 60px;
  }
  .big-text{
    font-size: 16px;
    font-weight: 600;
  }
  .table-row {
    .th,
    .td {
      &:nth-child(1) {
        flex: 0 0 14%;
        max-width: 14%;
      }
      &:nth-child(2) {
        flex: 0 0 18%;
        max-width: 18%;
      }
      &:nth-child(3) {
        flex: 0 0 12%;
        max-width: 12%;
      }
      &:nth-child(4) {
        flex: 0 0 11%;
        max-width: 11%;
      }
      &:nth-child(5) {
        flex: 0 0 11%;
        max-width: 11%;
      }
      &:nth-child(6) {
        flex: 0 0 8%;
        max-width: 8%;
        justify-content: center;
      }
      &:nth-child(7) {
        flex: 0 0 9%;
        max-width: 9%;
        justify-content: center;
      }
      &:nth-child(8) {
        flex: 0 0 9%;
        max-width: 9%;
        justify-content: center;
      }
      &:nth-child(9) {
        flex: 0 0 8%;
        max-width: 8%;
        justify-content: center;
      }
    }
  }
}
.gamer-redeem-transaction{
  .table-body .table-row{
    height: 70px;
    min-height: 70px;
  }
  .large-text{
    font-size: 18px;
    font-weight: 500;
  }
  .big-text{
    font-size: 16px;
    font-weight: 600;
  }
  .link{
    display: inline-block;
    text-decoration: none;
  }
  .logo{
    width: 20px;
    height: 20px;
    margin-right:5px;
    border-radius: 1px;
    border: solid 0.5px #d8e2d6;
    object-fit: cover;
  }
  .table-row {
    .th,
    .td {
      &:nth-child(1) {
        flex: 0 0 22%;
        max-width: 22%;
      }
      &:nth-child(2) {
        flex: 0 0 22%;
        max-width: 22%;
      }
      &:nth-child(3) {
        flex: 0 0 16%;
        max-width: 16%;
      }
      &:nth-child(4) {
        flex: 0 0 22%;
        max-width: 22%;
      }
      &:nth-child(5) {
        flex: 0 0 18%;
        max-width: 18%;
      }
    }
  }
}

.assets-table{
  .table-body .table-row{
    height: 60px;
    min-height: 60px;
  }
  .table-row {
    .th,
    .td {
      &:nth-child(1) {
        flex: 0 0 40%;
        max-width: 40%;
      }
      &:nth-child(2) {
        flex: 0 0 27%;
        max-width: 27%;
      }
      &:nth-child(3) {
        flex: 0 0 18%;
        max-width: 18%;
      }
      &:nth-child(4) {
        flex: 0 0 15%;
        max-width: 15%;
      }
    }
  }
}
.gamer-exchange-trans-table{
  .table-body .table-row{
    height: 80px;
    min-height: 80px;
  }
  .big-text{
    font-size: 16px;
    font-weight: 600;
    display: inline-flex;
    align-items: center;
  }
  .multiline-text{
    flex-wrap: wrap;
    span{
      width: 100%;
      margin-bottom: 3px;
    }
  }
  .link{
    display: inline-block;
    cursor: pointer;
    width: auto;
    margin-bottom: 0;
  }
  .logo{
    width: 60px;
    height: 60px;
    margin: 10px 20px 10px 0;
    border-radius: 6px;
    object-fit: cover;
  }
  .table-row {
    .th,
    .td {
      padding-top: 0;
      padding-bottom: 0;
      &:nth-child(1) {
        flex: 0 0 30%;
        max-width: 30%;
      }
      &:nth-child(2) {
        flex: 0 0 12%;
        max-width: 12%;
      }
      &:nth-child(3) {
        flex: 0 0 15%;
        max-width: 15%;
      }
      &:nth-child(4) {
        flex: 0 0 28%;
        max-width: 28%;
      }
      &:nth-child(5) {
        flex: 0 0 15%;
        max-width: 15%;
      }
    }
  }
}
.partner-analytics-transaction{
  .table-body .table-row{
    height: 70px;
    min-height: 70px;
  }
  .big-text{
    font-size: 16px;
    font-weight: 600;
    display: inline-flex;
    align-items: center;
  }
  .multiline-text{
    flex-wrap: wrap;
    span{
      width: 100%;
      margin-bottom: 3px;
    }
  }
  .link{
    display: inline-block;
    cursor: pointer;
    width: auto;
    margin-bottom: 0;
  }
  .logo{
    width: 60px;
    height: 60px;
    margin: 10px 20px 10px 0;
    border-radius: 6px;
    object-fit: cover;
  }
  .table-row {
    .th,
    .td {
      &:nth-child(1) {
        flex: 0 0 15%;
        max-width: 15%;
      }
      &:nth-child(2) {
        flex: 0 0 12%;
        max-width: 12%;
      }
      &:nth-child(3) {
        flex: 0 0 13%;
        max-width: 13%;
      }
      &:nth-child(4) {
        flex: 0 0 25%;
        max-width: 25%;
      }
      &:nth-child(5) {
        flex: 0 0 15%;
        max-width: 15%;
      }
       &:nth-child(6) {
        flex: 0 0 10%;
        max-width: 10%;
      }
      &:nth-child(7) {
        flex: 0 0 10%;
        max-width: 10%;
      }
    }
  }
}
.partner-analytics-gamers{
  .table-row{
    padding: 0 20px 0 20px;
    .td, .th{
      &:nth-child(1) {
        flex: 0 0 30%;
        max-width: 30%;
      }
      &:nth-child(2) {
        flex: 0 0 31%;
        max-width: 31%;
      }
      &:nth-child(3) {
        flex: 0 0 31%;
        max-width: 31%;
      }
      &:nth-child(4) {
        flex: 0 0 8%;
        max-width: 8%;
      }
    }
  }
}
.admin-tournaments-table{
  .table-row {
    .th,
    .td {
      &:nth-child(1) {
        flex: 0 0 16%;
        max-width: 16%;
      }
      &:nth-child(2) {
        flex: 0 0 20%;
        max-width: 20%;
      }
      &:nth-child(3) {
        flex: 0 0 10%;
        max-width: 10%;
      }
      &:nth-child(4) {
        flex: 0 0 8%;
        max-width: 8%;
      }
      &:nth-child(5) {
        flex: 0 0 10%;
        max-width: 10%;
        justify-content: center;
      }
      &:nth-child(6) {
        flex: 0 0 10%;
        max-width: 10%;
        justify-content: center;
      }
      &:nth-child(7) {
        flex: 0 0 10%;
        max-width: 10%;
        justify-content: center;
      }
      &:nth-child(8) {
        flex: 0 0 8%;
        max-width: 8%;
        justify-content: center;
      }
      &:nth-child(9) {
        flex: 0 0 8%;
        max-width: 8%;
        justify-content: center;
      }
    }
  }
}
.partner-analytics-assets{
  .table-row{
    padding: 0 20px 0 20px;
    .td, .th{
      &:nth-child(1) {
        flex: 0 0 25%;
        max-width: 25%;
      }
      &:nth-child(2) {
        flex: 0 0 25%;
        max-width: 25%;
      }
      &:nth-child(3) {
        flex: 0 0 25%;
        max-width: 25%;
      }
      &:nth-child(4) {
        flex: 0 0 25%;
        max-width: 25%;
      }
    }
  }
}
.gamer-tournament-table {
  .table-row {
    .th,
    .td {
      &:nth-child(1) {
        flex: 0 0 14%;
        max-width: 14%;
      }
      &:nth-child(2) {
        flex: 0 0 18%;
        max-width: 18%;
      }
      &:nth-child(3) {
        flex: 0 0 12%;
        max-width: 12%;
      }
      &:nth-child(4) {
        flex: 0 0 11%;
        max-width: 11%;
      }
      &:nth-child(5) {
        flex: 0 0 10%;
        max-width: 10%;
      }
       &:nth-child(6) {
        flex: 0 0 7%;
        max-width: 7%;
        justify-content: center;
      }
      &:nth-child(7) {
        flex: 0 0 8%;
        max-width: 8%;
        justify-content: center;
      }
      &:nth-child(8) {
        flex: 0 0 9%;
        max-width: 9%;
        justify-content: center;
      }
      &:nth-child(9) {
        flex: 0 0 10%;
        max-width: 10%;
        justify-content: center;
      }
    }
  }
}
.tournament-participants-table {
  .table-row {
    .th,
    .td {
      &:nth-child(1) {
        flex: 0 0 30%;
        max-width: 30%;
      }
      &:nth-child(2) {
        flex: 0 0 50%;
        max-width: 50%;
      }
      &:nth-child(3) {
        flex: 0 0 20%;
        max-width: 20%;
      }
    }
  }
}
.participants-image-box {
  width: 60px;
  height: 60px;
  margin: 0;
  border-radius: 50%;
  .participants-image {
    border-radius: 50%;
    height: 100%;
    width: 100%;
    min-width: 60px;
    min-height: 60px;
    object-fit: contain;
    &.default{
      display: none;
    }
    &[src=""],
    &:not([src]) {
      display: none;
      & ~.default{
        display: block;
      }
    }
  }
}
.gamer-assets-table {
  .table-row {
    .th,
    .td {
      &:nth-child(1) {
        flex: 0 0 30%;
        max-width: 30%;
      }
      &:nth-child(2) {
        flex: 0 0 20%;
        max-width: 20%;
      }
      &:nth-child(3) {
        flex: 0 0 15%;
        max-width: 15%;
      }
      &:nth-child(4) {
        flex: 0 0 20%;
        max-width: 20%;
      }
      &:nth-child(5) {
        flex: 0 0 15%;
        max-width: 15%;
      }
    }
  }
}
.gamer-exchange-transactions-table{
  .table-body .table-row{
    height: 70px;
    min-height: 70px;
  }
  .big-text{
    font-size: 16px;
    font-weight: 600;
    display: inline-flex;
    align-items: center;
  }
  .multiline-text{
    flex-wrap: wrap;
    span{
      width: 100%;
      margin-bottom: 3px;
    }
  }
  .link{
    display: inline-block;
    cursor: pointer;
    width: auto;
    margin-bottom: 0;
  }
  .logo{
    width: 60px;
    height: 60px;
    margin: 10px 20px 10px 0;
    border-radius: 6px;
    object-fit: cover;
  }
  .table-row {
    .th,
    .td {
      &:nth-child(1) {
        flex: 0 0 25%;
        max-width: 25%;
      }
      &:nth-child(2) {
        flex: 0 0 15%;
        max-width: 15%;
      }
      &:nth-child(3) {
        flex: 0 0 15%;
        max-width: 15%;
      }
      &:nth-child(4) {
        flex: 0 0 25%;
        max-width: 25%;
      }
      &:nth-child(5) {
        flex: 0 0 20%;
        max-width: 20%;
      }
    }
  }
}
.partner-analytics-tournament-reward-transaction{
  .table-body .table-row{
    height: 70px;
    min-height: 70px;
  }
  .big-text{
    font-size: 16px;
    font-weight: 600;
    display: inline-flex;
    align-items: center;
  }
  .multiline-text{
    flex-wrap: wrap;
    span{
      width: 100%;
      margin-bottom: 3px;
    }
  }
  .link{
    display: inline-block;
    cursor: pointer;
    width: auto;
    margin-bottom: 0;
  }
  .logo{
    width: 60px;
    height: 60px;
    margin: 10px 20px 10px 0;
    border-radius: 6px;
    object-fit: cover;
  }
  .table-row {
    .th,
    .td {
      &:nth-child(1) {
        flex: 0 0 15%;
        max-width: 15%;
      }
      &:nth-child(2) {
        flex: 0 0 22%;
        max-width: 22%;
      }
      &:nth-child(3) {
        flex: 0 0 14%;
        max-width: 14%;
      }
      &:nth-child(4) {
        flex: 0 0 14%;
        max-width: 14%;
      }
      &:nth-child(5) {
        flex: 0 0 20%;
        max-width: 20%;
      }
       &:nth-child(6) {
        flex: 0 0 15%;
        max-width: 15%;
      }
    }
  }
}
.admin-gamers-table{
  .table-body .table-row{
    height: 70px;
    min-height: 70px;
  }
  .strong{
    font-size: 16px;
    font-weight: 600;
  }
  .link{
    display: inline-block;
    margin-left: 5px;
  }
  .table-row {
    padding: 0 10px;
    .th,
    .td {
      &:nth-child(1) {
        flex: 0 0 24%;
        max-width: 24%;
      }
      &:nth-child(2) {
        flex: 0 0 23%;
        max-width: 23%;
      }
      &:nth-child(3) {
        flex: 0 0 23%;
        max-width: 23%;
      }
      &:nth-child(4) {
        flex: 0 0 20%;
        max-width: 20%;
      }
      &:nth-child(5) {
        flex: 0 0 10%;
        max-width: 10%;
        justify-content: center;
      }
    }
  }
}
.gamer-txn-history-table {
  .badge{
    &.badge-primary{
      color: #fff !important;
      background-color: #61a351 !important;
    }
  }
  .icon-image{
    width: 24px;
    height: 24px;
    margin-right:15px;
    border-radius: 1px;
    border: solid 0.6px #d8e2d6;
  }
  .table-row {
    .th,
    .td {
      &:nth-child(1) {
        flex: 0 0 22%;
        max-width: 22%;
      }
      &:nth-child(2) {
        flex: 0 0 14%;
        max-width: 14%;
      }
      &:nth-child(3) {
        flex: 0 0 12%;
        max-width: 12%;
      }
      &:nth-child(4) {
        flex: 0 0 16%;
        max-width: 16%;
      }
      &:nth-child(5) {
        flex: 0 0 14%;
        max-width: 14%;
      }
       &:nth-child(6) {
        flex: 0 0 22%;
        max-width: 22%;
      }
    }
  }
}
.partner-analytics-optIn-transaction{
  .table-row {
    .th,
    .td {
      &:nth-child(1) {
        flex: 0 0 15%;
        max-width: 15%;
      }
      &:nth-child(2) {
        flex: 0 0 15%;
        max-width: 15%;
      }
      &:nth-child(3) {
        flex: 0 0 16%;
        max-width: 16%;
      }
      &:nth-child(4) {
        flex: 0 0 14%;
        max-width: 14%;
      }
      &:nth-child(5) {
        flex: 0 0 20%;
        max-width: 20%;
      }
       &:nth-child(6) {
        flex: 0 0 20%;
        max-width: 20%;
      }
    }
  }
}
.gamer-offer-reward-txn-table{
  .table-body .table-row{
    height: 60px;
    min-height: 60px;
  }
  .big-text{
    font-size: 16px;
    font-weight: 600;
  }
  .table-row {
    .th,
    .td {
      &:nth-child(1) {
        flex: 0 0 22%;
        max-width: 22%;
      }
      &:nth-child(2) {
        flex: 0 0 22%;
        max-width: 22%;
      }
      &:nth-child(3) {
        flex: 0 0 20%;
        max-width: 20%;
      }
      &:nth-child(4) {
        flex: 0 0 16%;
        max-width: 16%;
      }
      &:nth-child(5) {
        flex: 0 0 20%;
        max-width: 20%;
      }
    }
  }
}
.tournament-reward-txn-table{
  .table-body .table-row{
    height: 60px;
    min-height: 60px;
  }
  .big-text{
    font-size: 16px;
    font-weight: 600;
  }
  .table-row {
    .th,
    .td {
      &:nth-child(1) {
        flex: 0 0 18%;
        max-width: 18%;
      }
      &:nth-child(2) {
        flex: 0 0 22%;
        max-width: 22%;
      }
      &:nth-child(3) {
        flex: 0 0 18%;
        max-width: 18%;
      }
      &:nth-child(4) {
        flex: 0 0 14%;
        max-width: 14%;
      }
      &:nth-child(5) {
        flex: 0 0 10%;
        max-width: 10%;
      }
      &:nth-child(6) {
        flex: 0 0 18%;
        max-width: 18%;
      }
    }
  }
}
