// left-nav-wrapper
.top-nav-wrapper {
  background-color: #37652c;
  width: 100%;
  height: 95px;
  display: flex;
  justify-content: space-between;
  padding: 0 40px;
  align-items: center;
  .logo-box {
    text-align: center;
    margin-right: 40px;
    img{
      max-height: 40px;
    }
  }
  .user-logo-box {
    margin-left: 0;
    margin-right: auto;
    img{
      max-height: 45px;
    }
  }
  .top-nav {
    margin: 0;
  }
  ul{
    display: flex;
    align-items: center;
  }
  li {
    .dropdown-toggle.btn-success{
      color: #fff !important;
    }
  }
}

// signout-btn
.signout-btn {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  margin: 0 0 0 30px;
  color: white;
  &:hover,
  &:active,
  &:focus{
    color: white;
  }
  .icon-signout {
    font-size: 24px;
  }
}
