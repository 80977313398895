// filter-tabs-wrapper
.filter-tabs-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  .right-side {
    display: flex;
    align-items: center;
    &.top-align-content{
      margin-top: 29px;
      margin-bottom: auto;
    }
  }
  .sort-dropdown {
    margin-right: 20px;
  }
  .filter-dropdown {
    margin-right: 20px;
  }
  .custom-dropdown{
    .dropdown-menu:after{
      right: auto;
      left: 25px;
    }
    &.right-arrow{
      .dropdown-menu::after{
        left: auto;
        right: 15px;
      }
    }
  }
}

// custom-tabs
.custom-tabs {
  border-bottom: 1px solid #d8e2d6;
  span {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px 14px;
    font-size: 20px;
    letter-spacing: 0.28px;
    color: #a3a2aa;
    font-weight: 700;
    cursor: pointer;
    position: relative;
    &.active {
      letter-spacing: 0.28px;
      color: #3f3d56;
      &:after{
        content: '';
        width: 45px;
        height: 6px;
        background-color: #61a351;
        position: absolute;
        bottom: 0;
        left: calc(50% - 22px);
      }
    }
    &:focus-visible,
    &:focus {
      outline: 0;
      box-shadow: 0 0 0.2rem rgba(#61a351, 50%);
    }
  }
  &.trans-tabs{
    margin-bottom: 30px;
  }
}
.page-sub-heading{
  font-size: 26px;
  font-weight: bold;
  letter-spacing: 0.36px;
  color: #3f3d56;

}
// grid-buttons
.grid-buttons {
  display: flex;
  span {
    cursor: pointer;
    background-color: white;
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    &:first-child {
      border-radius: 4px 0 0 4px;
    }
    &:last-child {
      border-radius: 0 4px 4px 0;
    }
    &.active {
      background-color: #3f3d56;
      color: white;
      border-radius: 4px;
    }
    &:focus-visible,
    &:focus {
      outline: 0;
      box-shadow: 0 0 0.2rem rgba(#3f3d56, 50%);
    }
  }
}

// games-grid-wrapper
.games-grid-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px 10px;
  width: calc(100% + 16px);
  overflow: auto;
  min-height: 300px;
  max-height: calc(100vh - 325px);
  &.large{
    margin-left: -12px;
    margin-right: -12px;
    .col-games-grid-box {
      padding: 0 12px;
      &:nth-child(odd){
        padding-right: 12px !important;
      }
      &:nth-child(even){
        padding-left: 12px !important;
      }
    }
  }
  &.no-scrollbar{
    overflow: visible;
    min-height: auto;
    max-height: initial;
  }
  .col-games-grid-box {
    width: 25%;
    padding: 0 8px;
    &:nth-child(odd){
      padding-right: 8px !important;
    }
    &:nth-child(even){
      padding-left: 8px !important;
    }
    @media screen and (min-width: 1440px) {
      width: 20%;
    }
    &.width-50{
      width: 50%;
    }
    &.fixed-width{
      width: 241px;
    }
  }
  .list-only{
    display: none;
  }
  .offer-detail-box{
    border: 0 !important;
    box-shadow: 0 0 20px 0 rgba(220, 229, 217, 0.5) !important;
    &.border-no-shadow{
      border: 1px solid #daead6 !important;
      box-shadow: none !important;
      &:hover,
      &:focus{
        box-shadow: 0 0 20px 0 rgba(220, 229, 217, 0.5) !important;
      }
    }
    .offer-detail-box-top{
      .image-box{
        display: none;
      }
    }
  }
  &.games-list-wrapper {
    display: block;
    min-height: 250px;
    // max-height: calc(100vh - 370px);
    margin: 0 0 10px;
    width: 100%;
    .grid-only{
      display: none !important;
    }
    .list-only{
      display: flex;
    }
    .offer-detail-box{
      display: flex;
      padding: 10px 0;

      .offer-detail-box-top{
        flex: 0 0 20%;
        max-width: 20%;
        .image-box{
          min-width: 50px;
          width: 50px;
          height: 50px;
          margin-right: 10px;
        }
        .offer-detail-box-top-body{
          flex: 1;
          display: flex;
          align-items: center;
          .left-side{
            strong{
              font-size: 16px;
              font-weight: 600;
              margin-bottom: 0;
            }
          }
        }
      }
      .games-desc-text {
        background: red;
        width: 30%;
      }
    }
    .col-games-grid-box {
      width: 100%;
      padding: 0;
      &:nth-child(odd){
        padding-right: 0 !important;
      }
      &:nth-child(even){
        padding-left: 0 !important;
      }
      .games-grid-box {
        display: flex;
        justify-content: space-between;
        height: auto;
        margin-bottom: 20px;
        padding: 10px;
        .games-image-box {
          width: 60px;
          height: 60px;
          margin: 0;
        }
        .games-grid-box-body {
          display: flex;
          align-items: center;
          margin-top: 0;
          width: calc(100% - 83px);
          strong {
            width: 15%;
            order: 1;
            padding: 0 10px;
          }
          .games-text {
            margin-top: 0;
            width: 12%;
            order: 3;
            padding: 0 10px;
          }
          .date-text{
            width: 12%;
          }
          .des-text{
            width: 30%;
            margin-top: 0;
            order: 2;
            padding: 0 10px;
          }
        }
        .games-anchor {
          order: 4;
          width: 7%;
          position: relative;
          max-width: 50px;
          margin-right: auto;
          margin-left: auto;
          text-align: center;
          .rounded{
            background: #fff;
            svg{
              font-size: 22px;
              color: #000;
            }
          }
        }
      }
    }
  }
  &.analytics-wrapper{
    .col-games-grid-box{
      width: 50%;
    }
    .games-grid-box{
      height: 346px;
      margin-bottom: 15px;
      box-shadow: 0 0 20px 0 rgba(220, 229, 217, 0.5);
      padding: 20px;
    }
    .games-card-top{
      display: flex;
      margin-bottom: 20px;
    }
    .games-image-box{
      width: 180px;
      height: 180px;
      margin: 0 20px 0 0;
      border-radius: 4px;
      background-color: #d8d8d8;
    }
    .card-top-content{
      font-size: 14px;
      font-weight: 500;
      width: calc(100% - 200px);
      .games-grid-box-body{
        margin: 4px 0 16px;
      }
      .content{
        margin-bottom: 16px;
        &:last-child{
          margin-bottom: 0;
        }
      }
      strong{
        font-size: 20px;
        line-height: 1.3;
      }
    }
    .link, 
    .text-green{
      color: #61a351;
      text-decoration: underline;
      strong{
        text-decoration: underline;
      }
    }
    .loader-label{
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }
    .progress-bar{
      height: 6px;
      border-radius: 3px;
      background-color: #daead6;
      .progress{
        height: 6px;
        border-radius: 3px;
        background-color: #61a351;
      }
    }
    .card-bottom-content{
      .bottom-list{
        margin-bottom: 16px;
        &:last-child{
          margin-bottom: 0;
        }
      }
      strong{
        display: inline-block;
        margin-left: 5px;
      }
    }
    .analytics-gamer-body{
      height: 80px;
      margin: 0 0 20px;
      padding: 10px 3px;
      border-radius: 4px;
      box-shadow: 0 0 20px 0 rgba(220, 229, 217, 0.5);
      background-color: #fff;
      display: none;
      .games-image-box{
        height: 60px;
        width: 60px;
        margin: 0;
        img{
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
      }
      td{
        font-size: 13px;
      }
      strong{
        font-weight: 600;
      }
      .text-green strong{
        font-weight: 500;
        text-decoration: underline;
      }
    }
    &.games-list-wrapper{
      .games-grid-box{
        display: none;
      }
      .col-games-grid-box{
        width: 100%;
      }
      .analytics-gamer-body{
        display: flex;
      }
    }
  }
  .custom-dropdown.more-dropdown{
    .dropdown-menu{
      padding: 8px 20px;
      min-height: 90px;
    }
  }
  &.gamer-detail-container{
    max-height: calc(100vh - 200px);
    margin-bottom: 0;
    .games-grid-box{
      height: auto;
      display: inline-block;
      .games-grid-box-body {
        margin-top: 15px;
      }
    }
  }
}

// games-grid-box
.games-grid-box {
  height: 395px;
  background-color: white;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 15px;
  position: relative;
  &.gamer-box{
    height: 265px;
    margin-bottom: 40px;
  }
  .games-image-box {
    max-height: 205px;
    border-radius: 4px;
    max-width: 205px;
    margin: 0 auto;
    height: 205px;
    img {
      border-radius: 4px;
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
  .games-grid-box-body {
    margin-top: 15px;
    position: relative;
    strong {
      color: #3f3d56;
      display: block;
      font-weight: 600;
      width: 80%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1.3;
    }
    .games-text {
      font-size: 13px;
      color: #3f3d56;
      margin-top: 8px;
      display: block;
      line-height: 1.3;
      &.des-text,
      p,
      strong,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      ul,
      ol,
      li,
      del,
      span{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-height: 20px;
        line-height: 20px;
        font-family: 'Poppins', sans-serif !important;
        font-size: 13px !important;
        color: #3f3d56 !important;
        p, h1, h2, h3, h4, h5, h6, ul, ol, span {
          display: none;
          &:first-child{
            display: block;
            width: 100%;
            strong {
              width: 100%;
            }
            p{
              display: none;
            }
          }
        }
      }
      &.date-text{
        font-size: 13px;
        color: #3f3d56;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 5px;
        span{
          font-size: 11px !important;
          color: #a3a2aa !important;
        }
      }
    }
    .games-anchor {
      position: absolute;
      top: -2px;
      right: 0;
      &.custom-dropdown.more-dropdown{
        .dropdown-toggle{
          min-width: 30px;
          margin-top: 0;
          margin-right: 0;
          height: 30px;
        }
      }
      .rounded{
        background: #61a351;
        height: 24px;
        width: 24px;
        border-radius: 100% !important;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        svg{
          font-size: 16px;
          color: #fff;
        }
      }
    }
  }
}

// games-inner-heading
.games-inner-heading {
  font-weight: 500;
  color: #3f3d56;
  margin-bottom: 10px;
  font-size: 16px;
}

// notification-icon-box
.notification-icon-box {
  position: relative;
  width: 32px;
  .icon-bell {
    color: #3f3d56;
    font-size: 20px;
    position: relative;
  }
  svg{
    cursor: pointer;
  }
  .notification-count {
    font-size: 10px;
    color: white;
    background-color: red;
    min-width: 18px;
    width: auto;
    height: 13px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    position: absolute;
    left: 12px;
    padding: 0 3px;
  }
}

// games-list-table-head
.games-list-table-head {
  justify-content: space-between;
  border-radius: 4px;
  box-shadow: 0 0 20px 0 rgba(220, 229, 217, 0.5);
  background-color: #ffffff;
  margin-bottom: 20px;
  min-height: 50px;
  padding: 10px;
  align-items: center;
  display: none;
  &.analytics-gamer-table{
    padding: 10px 3px;
  }
  &.active {
    display: flex;
  }
  .th {
    font-size: 13px;
    font-weight: 600;
    color: #3f3d56;
    line-height: 1.3;
    &.with-sort {
      display: flex;
      .sort-btn-box {
        align-items: center;
        margin-left: 7px;
        margin-top: 1px;
      }
    }
  }
  .table-head-body {
    display: flex;
    width: calc(100% - 83px);
    .th {
      &:nth-child(1) {
        width: 15%;
      }
      &:nth-child(2) {
        width: 30%;
      }
      &:nth-child(3) {
        width: 12%;
      }
      &:nth-child(4) {
        width: 12%;
      }
      &:nth-child(5) {
        width: 12%;
      }
      &:nth-child(6) {
        width: 12%;
      }
      &:nth-child(7) {
        width: 7%;
        justify-content: center;
      }
    }
  }
  &.offer-table-head{
    padding: 0 5px;
    .th {
      &:nth-child(1) {
        flex: 0 0 13%;
        max-width: 13%;
      }
      &:nth-child(2) {
        flex: 0 0 18%;
        max-width: 18%;
      }
      &:nth-child(3) {
        flex: 0 0 10%;
        max-width: 10%;
      }
      &:nth-child(4) {
        flex: 0 0 10%;
        max-width: 10%;
      }
      &:nth-child(5) {
        flex: 0 0 11%;
        max-width: 11%;
      }
      &:nth-child(6) {
        flex: 0 0 10%;
        max-width: 10%;
      }
      &:nth-child(7) {
        flex: 0 0 10%;
        max-width: 10%;
      }
      &:nth-child(8) {
        flex: 0 0 10%;
        max-width: 10%;
        justify-content: center;
      }
      &:nth-child(9) {
        flex: 0 0 8%;
        max-width: 8%;
        justify-content: center;
      }
    }
  }
}
.offer-table-body {
  display: flex;
  width: 100%;
  font-size: 13px;
  color: #67666c;
  line-height: 20px;
  padding: 0 5px;
  strong{
    font-size: 16px;
    font-weight: bold;
    display: block;
    color: #3f3d56;
    word-break: break-word;
    &.line-ellipses{
      display: -webkit-box;
    }
  }
  .link{
    display: inline-block;
    font-size: 11px;
    color: #61a351;
    text-decoration: underline;
  }
  .partner-image{
    position: static;
    max-height: 16px;
    max-width: 69px;
  }
  .td {
    display: flex;
    align-items: center;
    &:nth-child(1) {
      flex: 0 0 13%;
      max-width: 13%;
    }
    &:nth-child(2) {
      flex: 0 0 18%;
      max-width: 18%;
    }
    &:nth-child(3) {
      flex: 0 0 10%;
      max-width: 10%;
    }
    &:nth-child(4) {
      flex: 0 0 10%;
      max-width: 10%;
    }
    &:nth-child(5) {
      flex: 0 0 11%;
      max-width: 11%;
    }
    &:nth-child(6) {
      flex: 0 0 10%;
      max-width: 10%;
    }
    &:nth-child(7) {
      flex: 0 0 10%;
      max-width: 10%;
    }
    &:nth-child(8) {
      flex: 0 0 10%;
      max-width: 10%;
      justify-content: center;
    }
    &:nth-child(9) {
      flex: 0 0 8%;
      max-width: 8%;
      justify-content: center;
    }
  }
}
.gamer-offer-table-body {
  display: flex;
  width: 100%;
  font-size: 13px;
  color: #67666c;
  line-height: 20px;
  padding: 0 10px;
  strong{
    font-size: 16px;
    font-weight: bold;
    display: block;
    color: #3f3d56;
    word-break: break-word;
    &.line-ellipses{
      display: -webkit-box;
    }
  }
  .link{
    display: inline-block;
    font-size: 11px;
    color: #61a351;
    text-decoration: underline;
  }
  .partner-image{
    position: static;
    max-height: 16px;
    max-width: 69px;
  }
  .td {
    display: flex;
    align-items: center;
    &:nth-child(1) {
      flex: 0 0 15%;
      max-width: 15%;
    }
    &:nth-child(2) {
      flex: 0 0 22%;
      max-width: 22%;
    }
    &:nth-child(3) {
      flex: 0 0 12%;
      max-width: 12%;
    }
    &:nth-child(4) {
      flex: 0 0 10%;
      max-width: 10%;
    }
    &:nth-child(5) {
      flex: 0 0 10%;
      max-width: 10%;
    }
    &:nth-child(6) {
      flex: 0 0 8%;
      max-width: 8%;
    }
    &:nth-child(7) {
      flex: 0 0 8%;
      max-width: 8%;
      justify-content: center;
    }
    &:nth-child(8) {
      flex: 0 0 8%;
      max-width: 8%;
      justify-content: center;
    }
    &:nth-child(9) {
      flex: 0 0 7%;
      max-width: 7%;
      justify-content: center;
    }
  }
}
// sort-btn-box
.sort-btn-box {
  span {
    display: block;
    cursor: pointer;
    font-size: 4px;
    color: #3f3d56;
    &:first-child {
      margin-bottom: 2px;
    }
  }
}

// status-input
.status-input {
  position: absolute;
  right: 0;
  top: 0;
  width: 250px;
}

// games-offer-wrapper
.games-offer-wrapper {
  .games-offer-heading {
    display: block;
    font-weight: 500;
    color: #3f3d56;
    margin-bottom: 10px;
  }
  .games-offer-add-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 410px;
    height: 50px;
    cursor: pointer;
    border-radius: 4px;
    border: dashed 1px #d8e2d6;
    background-color: #f4f6f4;
    font-size: 14px;
    color: #3f3d56;
  }
  .games-offer-selected-list {
    display: flex;
    margin-top: 20px;
    .games-offer-selected-box {
      border-radius: 4px;
      border: solid 1px #d8e2d6;
      background-color: #f4f6f4;
      height: 50px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 0 15px;
      margin-right: 25px;
      .games-offer-text {
        font-size: 14px;
        font-weight: 500;
        color: #3f3d56;
        margin-right: 30px;
      }
      .icon-delete {
        cursor: pointer;
        color: red;
        font-size: 24px;
      }
    }
  }
}
.event-table-head{
  display: flex;
  padding: 0 20px;
}
.list-wrapper-body {
  display: flex;
  flex-direction: column;
  margin: 0 0 35px;
  overflow: auto;
  min-height: 300px;
  max-height: calc(100vh - 380px);
}
.offer-table-row {
  display: flex;
  width: 100%;
  margin: 0 0 20px;
  padding: 10px 20px;
  border-radius: 4px;
  box-shadow: 0 0 20px 0 rgba(220, 229, 217, 0.5);
  background-color: #ffffff;
  min-height: 60px;
  .td {
    font-size: 13px;
    color: #67666c;
    line-height: 20px;
  }
  .table-title-text {
    font-size: 16px;
    font-weight: 600;
    color: #3f3d56;
  }
  .custom-dropdown.more-dropdown .dropdown-toggle{
    height: 40px;
    min-width: 40px;
  }
}
.event-table-head .th,
.offer-table-row .td{
  display: flex;
  align-items: center;
  &:nth-child(1) {
    flex: 0 0 20%;
    max-width: 20%;
  }
  &:nth-child(2) {
    flex: 0 0 20%;
    max-width: 20%;
  }
  &:nth-child(3) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  &:nth-child(4) {
    flex: 0 0 10%;
    max-width: 10%;
    justify-content: center;
  }
}
.eventqx-table .th,
.eventqx-table .td{
  display: flex;
  align-items: center;
  &:nth-child(1) {
    flex: 0 0 20%;
    max-width: 20%;
  }
  &:nth-child(2) {
    flex: 0 0 20%;
    max-width: 20%;
  }
  &:nth-child(3) {
    flex: 0 0 60%;
    max-width: 60%;
  }
}
.image-with-content{
  display: flex;
  margin-bottom: 30px;
  .image-section{
    width: 205px;
    height: 205px;
    margin: 0 30px 0 0;
    border-radius: 4px;
    border: 1px solid #d8e2d6;
    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 4px;
    }
  }
  .content-section{
    display: flex;
    flex: 1;
    flex-direction: column;
    min-height: 100%;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
  }
}
.content-title{
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  color: #3f3d56;
  margin-bottom: 10px;
  svg{
    margin-top: -3px;
  }
}
.content-text{
  display: block;
  font-size: 14px;
  line-height: 1.8;
  color: #3f3d56;
  margin-bottom: 8px;
  word-break: break-word;
  &.mb-more{
    margin-bottom: 26px;
  }
}
.tag-box{
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0 20px 0 rgba(220, 229, 217, 0.5);
  margin-bottom: 35px;
  display: flex;
  flex-direction: column;
  min-height: 560px;
  .tag-box-inner{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .tag-content{
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .label{
    font-size: 13px;
    color: #3f3d56;
    margin-bottom: 8px;
    min-height: 19px;
  }
  .input-box{
    width: 160px;
    height: 50px;
    border-radius: 4px;
    border: solid 1px #d8e2d6;
    font-size: 14px;
    font-weight: 500;
    color: #3f3d56;
    padding: 15px;
  }
  .form-group{
    margin: 0 0 12px auto;
    max-width: 155px;
    width: 100%;
  }
  .text-year{
    font-size: 14px;
    font-weight: 500;
    text-align: right;
    color: #3f3d56;
  }
  .tag-text{
    font-size: 14px;
    font-weight: 500;
    color: #78759d;
    margin-bottom: 8px;
  }
  .tag-title{
    font-size: 36px;
    font-weight: bold;
    letter-spacing: 0.5px;
    color: #3f3d56;
  }
  .show-link{
    display: inline-block;
    padding: 0;
    width: 60px;
    margin-bottom: 40px;
  }
  .tag-image{
    width: 100%;
    min-height: 380px;
    max-height: 380px;
    img{
      height: auto;
      width: 100%;
    }
  }
  .tag-footer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .tag-text{
      margin-bottom: 0;
      display: flex;
      align-items: center;
    }
    .tag-title{
      font-size: 28px;
      letter-spacing: 0;
      margin-bottom: 4px;
      line-height: 1;
    }
  }
  .circle{
    width: 14px;
    height: 14px;
    margin-right:8px;
    border-radius: 50%;
    display: inline-block;
    &.blue{
      background-color: #5fa6c9;
    }
    &.green{
      background-color: #61a351;
    }
    &.yellow{
      background-color: #ffdd89;
    }
    &.pink{
      background-color: #ffdad8;
    }
    &.gray{
      background-color: #e5e5ea;
    }
  }
}
.offer-detail-box{
  &.analytics-box{
    padding: 20px 20px 10px;
    border-radius: 10px;
    box-shadow: 0 0 20px 0 rgba(220, 229, 217, 0.5);
    border: 0;
    .offer-detail-box-top {
      align-items: center;
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: 1px solid #d8e2d6;
      .image-box{
        min-width: 40px;
        width: 40px;
        height: 40px;
        border-radius: 4px;
        margin-right: 20px;
      }
      .offer-detail-box-top-body .left-side strong{
        margin-bottom: 0;
      }
      .link-text{
        font-size: 12px;
        font-weight: 500;
        color: #61a351;
        text-decoration: underline;
      }
    }
  }
  .custom-dropdown.more-dropdown{
    .dropdown-menu{
      min-height: 140px;
    }
  }
}
.analytics-bottom-box{
  .row{
    margin-left: -17px;
    margin-right: -17px;
  }
  .col,
  .col-sm-6{
    padding-left: 17px;
    padding-right: 17px;
  }
}

.offer-link{
  display: inline-block;
  color: #61a351;
  text-decoration: underline;
  cursor: pointer;
  font-size: 15px;
  margin-bottom: 15px;
}
.game-content-right{
  position: absolute;
  top: 0;
  right: 0;
  text-align: right;
}
.track-horizontal{
  height: calc(100vh - 310px) !important;
  & > div:nth-child(1){
    overflow: hidden !important;
    overflow-y: auto !important;
  }
  & > div:nth-child(2){
    display: none;
  }
  &.track-horizontal-full{
    height: calc(100vh - 270px) !important;
  }
  &.track-horizontal-list-large{
    height: calc(100vh - 340px) !important;
    & > div:nth-child(1){
      overflow: visible !important;
      overflow-y: visible !important;
    }
  }
  &.track-horizontal-list{
    height: calc(100vh - 365px) !important;
    & > div:nth-child(1){
        overflow: visible !important;
        overflow-y: visible !important;
    }
  }
  &.small-height{
    height: calc(100vh - 400px) !important;
  }
  &.track-horizontal-list-small{
    height: calc(100vh - 460px) !important;
    & > div:nth-child(1){
      overflow: visible !important;
      overflow-y: visible !important;
    }
  }
}

.gamer-game-head{
  .table-head-body {
    width: 100%;
    .th {
      .two-value{
        display: inline-block;
        min-width: 92px;
      }
      &:nth-child(1) {
        width: 92px;
      }
      &:nth-child(2) {
        width: calc(40% - 92px);
      }
      &:nth-child(3) {
        width: 15%;
      }
      &:nth-child(4) {
        width: 15%;
        justify-content: center;
      }
      &:nth-child(5) {
        width: 15%;
        justify-content: center;
      }
      &:nth-child(6) {
        width: 15%;
        justify-content: center;
      }
      
    }
  }
}
.gamer-offer-head{
  .table-head-body {
    width: 100%;
    .th {
      &:nth-child(1) {
        width: 15%;
      }
      &:nth-child(2) {
        width: 22%;
      }
      &:nth-child(3) {
        width: 12%;
      }
      &:nth-child(4) {
        width: 10%;
      }
      &:nth-child(5) {
        width: 10%;
      }
      &:nth-child(6) {
        width: 8%;
      }
      &:nth-child(7) {
        width: 8%;
        justify-content: center;
      }
      &:nth-child(8) {
        width: 8%;
        justify-content: center;
      }
      &:nth-child(9) {
        width: 7%;
        justify-content: center;
      }
    }
  }
}
.gamer-game-body{
  width: 100%;
  min-height: auto;
  .game-image {
    height: 60px;
    width: 60px;
    margin-right: 32px;
    object-fit: contain;
    border-radius: 6px;
  }
  .table-row {
    margin-bottom: 20px;
    padding: 10px;
    .td{
      font-size: 12px;
      font-weight: normal;
      color: #3f3d56;
      strong{
        font-size: 14px;
        font-weight: 600;
      }
      &:nth-child(1) {
        width: 40%;
      }
      &:nth-child(2) {
        width: 15%;
      }
      &:nth-child(3) {
        width: 15%;
        justify-content: center;
      }
      &:nth-child(4) {
        width: 15%;
        justify-content: center;
      }
      &:nth-child(5) {
        width: 15%;
        justify-content: center;
      }
    }
    .link{
      font-size: 11px;
      font-weight: normal;
      color: #61a351;
      text-decoration: underline;
    }
  }
}
.inner-content{
  margin-bottom: 16px;
  ul{
    list-style: disc;
    padding-left: 20px;
    margin-bottom: 10px;
    ul{
      list-style: circle;
      margin-top: 10px;
    }
  }
  code{
    color: inherit;
    background: #e5e5ea;
    padding: 2px 5px;
  }
  p{
    margin-bottom: 10px;
  }
  ol{
    list-style: auto;
    padding-left: 20px;
    margin-bottom: 10px;
    ol{
      list-style: auto;
      margin-top: 10px;
    }
  }
}
.analytics-gamer-table{
  .th,
  .td{
    &:nth-child(1) {
      width: 8%;
    }
    &:nth-child(2) {
      width: 15%;
    }
    &:nth-child(3) {
      width: 10%;
    }
    &:nth-child(4) {
      width: 10%;
    }
    &:nth-child(5) {
      width: 16%;
    }
    &:nth-child(6) {
      width: 17%;
    }
    &:nth-child(7) {
      width: 16%;
    }
    &:nth-child(8) {
      width: 8%;
      justify-content: center;
    }
  }
}
.game-tour-detail{
  padding-bottom: 10px;
  .content-section {
    .content-label{
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 0;
      span{
        font-weight: 500;
      }
    }
  }
}
