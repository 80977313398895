// main-wrapper
.main-wrapper {
  display: flex;
}

// main-right-wrapper
.main-right-wrapper {
  padding: 0 30px;
  width: calc(100% - 250px);
  margin-left: 250px;
}

// main-head-wrapper
.main-head-wrapper {
  padding-bottom: 20px;
  padding-top: 41px;
  border-bottom: 1px solid #d8e2d6;
  margin: 0 0 31px;
  .main-heading {
    font-size: 16px;
    font-weight: 500;
    color: #78759d;
  }
  &.main-heading-with-right-side {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 30px;
    padding-bottom: 10px;
  }
}
// back-button-link
.back-button-link {
  color: #78759d;
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  &:hover {
    color: #78759d;
  }
  .icon-back-arrow {
    font-size: 24px;
    margin-right: 5px;
  }
}

// page-head-wrapper
.page-head-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.page-head-right {
  display: flex;
  white-space: nowrap;
  .back-btn {
    margin-right: 20px;
  }
  .delete-btn {
    margin-left: 20px;
  }
  .custom-search {
    margin-right: 20px;
  }
}
.page-heading {
  font-size: 36px;
  font-weight: bold;
  letter-spacing: 0.5px;
  color: #3f3d56;
  word-break: break-word;
  svg{
    font-size: 24px;
    color: #c9c8cf;
    margin-left: 5px;
  }
  &.small-icon{
    svg{
      font-size: 20px;
      margin-left: 5px;
    }
  }
  &.head-capital {
    text-transform: capitalize;
  }
}

// page-inner-box
.page-inner-box {
  background-color: white;
  border-radius: 10px;
  padding: 30px;
  min-height: 77vh;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  &.with-fixed-footer{
    position: relative;
    padding-bottom: 125px;
  }
  &.message-main-box{
    min-height: 50vh;
    padding-bottom: 0;
  }
  &.with-back-header{
    min-height: calc(100vh - 200px);
    padding-bottom: 0;
  }
  &.setting-box{
    min-height: calc(100vh - 170px);
    position: relative;
    padding-bottom: 125px;
    .form-group{
      margin-bottom: 28px;
    }
  }
  &.setting-box-partner{
    min-height: calc(100vh - 250px);
    position: relative;
    padding-bottom: 125px;
    .form-group{
      margin-bottom: 28px;
    }
    .page-inner-box{
      min-height: auto;
      padding: 0;
    }
  }
}

// add-logo-wrapper
.add-logo-wrapper {
  margin-bottom: 20px;
  .add-logo-wrapper-inner {
		display: flex;
    align-items: flex-end;
	}
  strong {
    font-size: 14px;
    font-weight: 500;
    color: #3f3d56;
    display: block;
    margin-bottom: 10px;
  }
  .logo-box {
    width: 200px;
    height: 200px;
    border-radius: 35px;
    border: solid 1px #d8e2d6;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    img {
      max-height: 100%;
      max-width: 100%;
      border-radius: 35px;
      object-fit: contain;
      height: 100%;
      width: 100%;
    }
  }
}

// profile-form
.profile-form {
  .col-sm-6 {
    padding: 0;
  }
}

// page-inner-heading
.page-inner-heading {
  font-size: 20px;
  font-weight: 600;
  color: #3f3d56;
  border-bottom: 1px solid #d8e2d6;
  padding-bottom: 10px;
  margin-bottom: 30px;
  &.small{
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 20px;
  }
  &.medium{
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 30px;
  }
}

// profile-radio-list
.profile-radio-list {
  .custom-radio {
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

// heading-with-textarea-wrapper
.heading-with-textarea-wrapper {
  margin-bottom: 12px;
  strong {
    font-size: 14px;
    font-weight: 500;
    color: #3f3d56;
    display: block;
    margin-bottom: 10px;
  }
  .form-group {
    margin-bottom: 0;
  }
  textarea {
    height: 350px;
    padding: 15px;
    &::-webkit-scrollbar {
      width: 6px;
      margin-right: 10px;
      right: 50px;
    }
    &::-webkit-scrollbar-track {
      background: #d8e2d6;
    }
    &::-webkit-scrollbar-thumb {
      background: #8ab67f;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #8ab67f;
    }
  }
  &.add-suggestion-wrapper {
    margin-top: 32px;
    textarea {
      height: 200px;
    }
  }
}
.tc-content{
  width: 100%;
  height: 350px;
  padding: 10px;
  border-radius: 4px;
  border: solid 1px #d8e2d6;
  max-height: 350px;
  overflow: auto;
  font-size: 14px;
  line-height: 1.4;
  font-weight: 500;
  color: #3f3d56;
  p{
    margin-bottom: 1rem;
  }
  ol{
    padding-left: 30px;
    li{
      list-style-type: decimal;
      margin-bottom: 1rem;
    }
  }
  ul{
    padding-left: 30px;
    &.lower-alpha{
      li{
        list-style-type: lower-alpha;
      }
      ol{
        padding-left: 30px;
        counter-reset: list;
        li{
          list-style: none;
          margin-bottom: 1rem;
          text-indent: -25px;
          padding-left: 25px;
          margin-left: -25px;
          &::before{
            content: "(" counter(list, decimal) ") ";
            counter-increment: list;
          }
        }
      }
    }
  }
  .underline{
    text-decoration: underline;
  }
  .text-blue{
    color: #656fec;
  }
  a{
    color: #656fec;
  }
}
// profile-steps-box
.profile-steps-box {
  position: relative;
  .row {
    margin: 0 -12px;
    [class^="col-"], [class*=" col-"] {
      padding: 0 12px;
    }
  }
}

// profile-pending-box
.profile-pending-box {
  border-radius: 10px;
  box-shadow: 0 0 20px 0 rgba(220, 229, 217, 0.5);
  background-color: #ffffff;
  padding: 50px;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  .left-side {
    width: 50%;
    padding-right: 30px;
    h2 {
      font-size: 40px;
      color: #61a351;
      margin-bottom: 20px;
      font-weight: bold;
    }
    p {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.88;
      color: #3f3d56
    }
    .btn {
      min-width: 200px;
      margin-top: 20px;
    }
  }
  .right-side {
    width: 50%;
    padding-left: 20px;
    img{
      max-width: 390px;
    }
  }
}

// heading-with-textarea-outer-wrapper
.heading-with-textarea-outer-wrapper {
  border-radius: 10px;
  box-shadow: 0 0 20px 0 rgba(220, 229, 217, 0.5);
  background-color: #ffffff;
  padding: 30px;
  .heading-with-textarea-wrapper {
    margin-bottom: 0;
  }
  .form-group {
    margin-bottom: 20px;
  }
  textarea {
    height: 230px;
  }
  .btn-wrapper {
    text-align: right;
    .btn {
      min-width: 200px;
    }
  }
}
// Dashboard
.chart-container {
  border-radius: 4px;
  box-shadow: 0 0 20px 0 rgba(220, 229, 217, 0.5);
  background-color: #ffffff;
  padding: 20px 20px 10px;
  margin-bottom: 30px;
  .chart-title {
    font-size: 20px;
    font-weight: 600;
    color: #3f3d56;
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid #d8e2d6;
  }
  .chart-container-inner {
    min-height: 300px;
    height: 300px;
    margin-bottom: 20px;
  }
  .chart-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    .chart-info-pills {
      padding: 7px 5px;
      border-radius: 4px;
      border: solid 1px #d8e2d6;
      background-color: #fcfdfc;
      font-size: 11px;
      font-weight: 500;
      color: #78759d;
      display: flex;
      align-items: center;
      .chart-color {
        width: 14px;
        height: 14px;
        margin-right: 5px;
        &.primary{
          background-color: #61a351;
        }
        &.secondary{
          background-color: #3f3d56;
        }
      }
    }
  }
}
// Dashboard
.dashboard-container{
  padding: 0 75px;
  a {
    &:focus-visible,
    &:focus {
      .card{
        outline: 0;
        box-shadow: 0 0 0.2rem rgba(#61a351, 50%);
      }
    }
  }
  .row{
    margin-right: -27px;
    margin-left: -27px;
  }
  [class^="col-"], [class*=" col-"] {
    padding: 0 27px;
  }
  .card{
    border-radius: 30px;
    box-shadow: 0 0 20px 0 rgba(55, 101, 44, 0.2);
    background-color: #ffffff;
    margin-bottom: 54px;
    padding: 30px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    .card-image{
      height: 52px;
      margin-bottom: 10px;
      color: #61a351;
      svg,
      span,
      img{
        font-size: 45px;
        height: 45px;
        width: 45px;
      }
    }
    .card-title{
      color: #61a351;
      font-size: 26px;
      font-weight: 600;
    }
    .blur-image{
      color: #61a351;
      opacity: 0.19;
      position: absolute;
      bottom: -14px;
      right: -12px;
      img,
      svg,
      span{
        font-size: 85px;
        height: 85px;
        width: 85px;
        display: inline-block;
      }
      svg{
        font-size: 85px;
      }
    }
  }
}
// Filter
.filter-button{
  width: 150px;
  height: 50px;
  padding: 13px 20px;
  border-radius: 4px;
  background-color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  color: #3f3d56;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  cursor: pointer;
  .icon-filter{
    font-size: 24px;
    margin-right: 10px;
  }
  .count{
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 14px;
    background-color: #61a351;
    font-size: 11px;
    font-weight: 500;
    color: #ffffff;
    border-radius: 100%;
  }
}
.filter-container{
  padding: 0;
  width: 100%;
  .filter-heading{
    font-size: 18px;
    font-weight: 500;
    color: #3f3d56;
    margin-bottom: 15px;
  }
  .form-group{
    margin-bottom: 20px;
  }
  .row{
    margin-right: -8px;
    margin-left: -8px;
  }
  .col{
    margin-left: 8px;
    margin-right: 8px;
  }
  .col-sm-4{
    max-width: 252px;
    padding-left: 8px;
    padding-right: 8px;
  }
  .filter-button-container{
    text-align: right;
    cursor: default;
    .btn{
      min-width: 120px;
      margin-right: 20px;
      &:last-child{
        margin-right: 0;
      }
    }
  }
}

// Admin pages start from here
.content-heading{
  font-size: 20px;
  font-weight: 600;
  color: #3f3d56;
  margin: 0 0 20px;
  padding-top: 21px;
  padding-bottom: 20px;
  border-bottom: 1px solid #d8e2d6;
  &.first{
    padding-top: 10px;
  }
}
.content-sub-heading{
  font-size: 14px;
  color: #3f3d56;
  margin: 0 0 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #d8e2d6;
}
.content-section{
  width: 100%;
  margin-bottom: 30px;
  .content-label{
    font-size: 14px;
    font-weight: normal;
    color: #3f3d56;
    margin-bottom: 10px;
  }
  .content-des{
    font-size: 14px;
    font-weight: 500;
    color: #3f3d56;
    line-height: 1.4;
    max-height: 300px;
    overflow: auto;
    word-wrap: break-word;
    p{
      margin-bottom: 15px;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
  .help-text{
    margin-top: 10px;
    font-size: 11px;
    color: #3f3d56;
  }
  &.disabled{
    opacity: 0.5;
  }
}

.heading-with-button{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d8e2d6;
  padding: 10px 0;
  margin-bottom: 20px;
  .content-heading{
    border-bottom: 0;
    padding: 0;
    margin: 0;
  }
}
.fixed-footer-wrapper{
  background: #fff;
  text-align: right;
  padding: 20px 0;
  border-top: 1px solid #d8e2d6;
  position: fixed;
  bottom: 0;
  left: 310px;
  width: calc(100% - 370px);
  z-index: 2;
}
.user-count{
  font-size: 20px;
  font-weight: 600;
  color: #3f3d56;
  margin-top: 44px;
}
  // notification page
.notification-container{
  display: flex;
  width: 100%;
  flex-direction: column;
  li{
    display: flex;
    width: 100%;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 0 20px 0 rgba(220, 229, 217, 0.5);
    margin-bottom: 20px;
    padding: 15px;
    .heading-text{
      font-size: 14px;
      font-weight: 600;
      color: #3f3d56;
      margin-top: 5px;
      margin-bottom: 10px;
    }
    .time{
      font-size: 13px;
      color: #67666c;
    }
    &.read{
      .heading-text{
        font-weight: 400;
      }
    }
  }
  .notification-name{
    height: 30px;
    width: 30px;
    border-radius: 100%;
    background: #37652c;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    margin-right: 15px;
    img{
      height: 25px;
      width: 25px;
    }
  }
}
.big-link{
  font-size: 16px;
  font-weight: 500;
  color: #37652c;
  margin-left: auto;
  margin-right: 0;
  &:hover{
    color: #37652c;
    text-decoration: underline;
  }
}
.div-disable{
  pointer-events: none;
  opacity: 0.7;
}
.gamer-list-container {
  display: flex;
  padding: 20px 0;
  border-bottom: 1px solid #d8e2d6;
  align-items: center;
  justify-content: space-between;
  color: #3f3d56;
  line-height: 1.25;
  .text-bold {
    font-size: 13px;
    font-weight: 600;
  }
  .text-points{
    font-size: 16px;
    font-weight: 500;
  }
  .text-total{
    font-size: 18px;
    font-weight: 600;
  }
}
.profile-image{
  border: 1px solid #467b3a;
  height: 50px;
  width: 50px;
  border-radius: 100%;
  padding: 2px;
  background: #fff;
  margin-right: 10px;
  img{
    height: 45px;
    width: 45px;
    border-radius: 100%;
    object-fit: cover;
  }
  &.large{
    height: 100px;
    width: 100px;
    padding: 4px;
    margin-right: 30px;
    img{
      height: 90px;
      width: 90px;
    }
  }
}
.profile-title{
  font-weight: 700;
  font-size: 30px;
  color: #333;
  margin-bottom: 5px;
  span{
    font-size: 16px;
    font-weight: normal;
  }
}
.profile-text{
  font-size: 13px;
  color: #3f3d56;
  margin-bottom: 5px;
}
.right-more-content{
  display: flex;
  flex-direction: column;
  text-align: right;
  .text-gamer-bold{
    margin-bottom: 16px;
  }
  .link{
    font-size: 13px;
    color: #61a351;
    text-decoration: underline;
    display: inline-flex;
    align-self: end;
  }
  .badge {
    margin-left: 150px;
    width: 70px;
  }
}
.content-with-image{
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 700;
  color: #3f3d56;
  img{
    max-width: 22px;
    max-height: 22px;
    margin-right: 5px;
  }
  span{
    font-weight: 500;
    margin-right: 10px;
    display: inline-block;
  }
}
.gamer-card{
  padding: 38px 0 25px 20px;
  border-radius: 8px;
  box-shadow: 0 0 20px 0 rgba(220, 229, 217, 0.5);
  background-color: #fff;
  color: #3f3d56;
  position: relative;
  margin-bottom: 30px;
  .card-title{
    font-size: 36px;
    font-weight: bold;
    letter-spacing: 0.5px;
  }
  .card-text{
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 22px;
  }
  .link{
    font-size: 13px;
    color: #61a351;
    text-decoration: underline;
  }
  img{
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
.list-content{
  display: flex;
  align-items: center;
  min-width: 50%;
}
.text-block{
  display: block;
  width: 33%;
  span{
    display: block;
    text-align: center;
    font-size: 10px;
    color: #3f3d56;
    &:nth-child(1){
      font-size: 12px;
      font-weight: 500;
    }
    &:nth-child(2){
      font-size: 14px;
    }
  }
  .link{
    font-size: 13px;
    text-decoration: underline;
    color: #61a351;
  }
}
.leaderboard{
  position: relative;
  .bg-leader{
   position: absolute;
   top: -30px;
   left: -30px;
   width: calc(100% + 60px);
   max-height: 500px;
   min-height: 500px;
  }
  .tour-heading{
    display: flex;
    justify-content: space-between;
    position: relative;
  }
  .tour-heading-inner{
    font-size: 16px;
    font-weight: 700;
    color: #3f3d56;
    .link-text{
      font-weight: 500;
      text-decoration: underline;
      display: inline-block;
      margin-left: 7px;
    }
    span{
      display: block;
      &:not(:last-child){
        margin-bottom: 4px;
      }
    }
  }
  .leaderboard-container{
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    max-width: 535px;
    position: relative;
    padding-top: 100px;
    margin-bottom: 40px;
    .leader-content{
      position: absolute;
      &.first{
        top: 108px;
        left: 53px;
      }
      &.second{
        top: 28px;
        left: 223px;
      }
      &.third{
        top: 148px;
        right: 57px;
      }
      .leader-image{
        height: 90px;
        width: 90px;
        margin-bottom: 5px;
        border: solid 2px #61a351;
        &[src=""],
        &:not([src]) {
          display: none;
          & ~.default{
            display: block;
          }
        }
      }
    }
  }
}
.leader-content{
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 100px;
  .leader-image{
    height: 80px;
    width: 80px;
    border: solid 1px #3f3d56;
    border-radius: 50%;
    margin-bottom: 10px;
    &.default{
      display: none;
    }
    &[src=""],
    &:not([src]) {
      display: none;
      & ~.default{
        display: block;
      }
    }
  }
  .leader-text{
    font-size: 14px;
    font-weight: 600;
    color: #3f3d56;
    display: block;
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-height: 21px;
  }
  .crown-icon{
    width: 32px;
    height: 26px;
    position: absolute;
    top: -23px;
    left: 50%;
    transform: translate(-50%, 0);
  }
}
.leader-box-container{
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}
.leader-box{
  width: 200px;
  margin: 0 15px 40px;
  height: 140px;
  border-radius: 6px;
  background-color: #eefbeb;
  .leader-content{
    margin-top: -40px;
    .leader-text{
      max-width: 180px;
    }
  }
  .badge{
    width: 60px;
    height: 40px;
    margin: 10px auto 20px;
    border-radius: 6px;
    background-color: #3f3d56;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    font-weight: bold;
    color: #fff;
  }
}
.current-place {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #3f3d56;
  font-weight: 600;
  color: #fff;
  max-width: 970px;
  border-radius: 39px;
  padding: 27px 40px;
  font-size: 16px;
  margin: -45px auto 70px;
}
.disable-click {
  pointer-events: none;
}
.opt-in-main{
  flex: auto;
  .opt-in-container{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .date{
      margin: 0 45px 0 0 !important;
    }
  }
}
.opt-in-text{
  font-size: 12px;
  font-weight: 500;
  color: #3f3d56;
  display: flex;
  align-items: center;
  strong{
    font-weight: 600 !important;
    color: #61a351 !important;
    margin: 0 4px 0 5px !important;
  }
  svg{
    color: #61a351;
    font-size: 20px;
  }
}
